.catalog-card {
    height: 384px;
    width: 304px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
    padding: 24px;
    text-decoration: none !important;
    transition: all 0.5s;
    &:hover {
        transform: scale(1.05);
    }

    > p {
        color: #204569;
        font-family: "MontSemiBold";
        font-size: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 0;
        overflow: hidden;
        width: 256px;
    }
    > img {
        height: 256px;
        width: 256px;
        margin-top: 16px;
        object-fit: cover;
    }
    > a {
        color: #3796f6;
        margin-top: 24px;
        font-family: "MontSemiBold";
        text-transform: uppercase;
        font-size: 10px;
        display: flex;
        text-decoration: none;
        align-items: center;
        > img {
            margin-left: 4px;
        }
    }
}

.main-card {
    margin: 0 16px;
    margin-bottom: 32px;
}
.category-card {
    margin-right: 32px;
    margin-bottom: 32px;
}
