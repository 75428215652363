.subscriptions_page {
  display: flex;
  flex-flow: column;
  font-family: "MontRegular",sans-serif;
  h3 {
    padding: 20px 0;
  }
  .subscriptions_block {
    background: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
    padding: 32px 40px;
    border-radius: 10px;


    .option{
      margin-bottom: 17px;
      display: flex;
      align-items: center;
      .block_search .input-group .search_info input{
        width: 280px;
      }
      .select_wrapper{
        margin-left: 24px;
        width: 200px;
      }
    }
  }


  .pagination-container {
    border-top: 1px solid rgba(32, 69, 105, 0.25);
    padding-top: 32px;
    .pagination-list{
      margin: 0;
      padding: 0;
      li a{
        &:hover{
          text-decoration: none;
        }
      }
      .pagination-ellipsis{
        a{
          color: #3796F6;
        }
      }
    }

  }

  .in_stock_table {
    .table_body .table_row .row{
      height: 60px;
    }

    .table_header .table_row .row{
      height: 45px;
    }
    .transactions_columns {
      .table_row {
        .row {
          width: 100%;
          display: flex;
          margin: 0;
          align-items: center;
          & > :first-child {
            width: 10%;
          }
          & > :nth-child(2) {
            width: 10%;
          }
          & > :nth-child(3) {
            width: 30%;
          }
          & > :nth-child(4) {
            width: 10%;
          }
          & > :nth-child(5) {
            width: 10%;
          }
          & > :nth-child(6) {
            width: 10%;
          }
          & > :nth-child(7) {
            width: 10%;
          }
          & > :last-of-type {
            width: 10%;
          }
        }
        .status{
          font-family: "MontSemiBold",sans-serif;
          font-size: 14px;
          line-height: 19px;
          text-transform: capitalize;
          .requested{
            color: #204569;
          }
          .active{
            color: #2CC197;
          }
          .canceled{
            color: rgba(32, 69, 105, 0.5);
          }
        }
        .link{
          a{
            color: #3796F6;
            font-family: "MontSemiBold",sans-serif;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            margin-right: 20px;
            &:hover{
              text-decoration: none;
              opacity: 0.7;
            }
            span{
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .btn_info{
          span{
            color: #204569;
            font-size: 14px;
            line-height: 18px;
          }
          button{
            color: #E44B4B;
            font-family: "MontSemiBold",sans-serif;
            font-size: 10px;
            line-height: 16px;
            text-transform: uppercase;
            &:hover{
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}
.dialog_subscription{
  .title{
    color: #204569;
    font-family: "MontBold",sans-serif;
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 42px;
    margin-bottom: 32px;
  }
  .descriptions{
    color: #204569;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    span{
      font-family: "MontSemiBold",sans-serif;
    }
  }
  .btn_wrapper{
    display: flex;
    align-items: center;
    padding-top: 32px;
    margin-top: 32px;
    border-top: 1px solid rgba(32, 69, 105, 0.25);
    button{
      margin-right: 16px;
    }
  }
}
