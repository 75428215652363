.suggested1 {
    width: 80% !important;
}
.suggested2 {
    width: 10% !important;
    background-color: #fff !important;
}
.suggested3 {
    width: 10% !important;
}

.table_row1 {
    height: auto;
    flex-flow: column;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 58px;
    border-top: 1px solid rgba(32, 69, 105, 0.25);
    text-decoration: none;
    .row {
        width: 100%;
        display: flex;
        height: 60px;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    .light {
        color: #204569 !important;
        font-family: MontRegular, sans-serif !important;
        font-size: 16px !important;
    }
}
.table_header {
    .table_row1 {
        border: none;
    }
}
.cart_title {
    color: #204569;
    font-family: MontRegular, sans-serif;
    font-size: 16px;
}
.title_block {
    border: none;
}
hr {
    border: none;
}
.activity_page {
    display: flex;
    flex-flow: column;
    h3 {
        padding: 20px 0;
    }
    .pagination_info_wrapper {
        padding-bottom: 20px;
        border-top: 1px solid rgba(32, 69, 105, 0.25);
    }
    .activity_block {
        padding-bottom: 10px;
    }
}

.in_stock_table {
    .title_block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cart_buttons {
            margin: 0;
        }
    }
    .cart_container {
        .cart_items {
            margin: 0;
        }
    }
}
.cart_items {
    .cart_list {
        border: none;
        box-shadow: none;
        padding-left: 0;
        .cart_item {
            cursor: pointer;
            margin-bottom: 20px;
            border-bottom: 1px solid rgba(32, 69, 105, 0.25);
            padding: 15px 0px;
            padding-bottom: 32px;
            list-style: none !important;
        }
    }
    .cart_item_image {
        width: 80px;
        height: 80px;
        float: left;
        img {
            width: 80px;
            height: 80px;
            border-radius: 4px;
        }
    }
}
.cart_item_text {
    margin-top: 6px;
    color: #204569;
    font-size: 16px;
    font-family: "MontSemiBold";
    .row {
        display: flex;
        align-items: center;
        margin: 0;
        > input {
            border: 1px solid #dee4e9;
            border-radius: 4px;
            font-size: 14px;
            font-family: "MontRegular";
            color: #204569;
            width: 42px;
            height: 28px;
            text-align: center;
        }
        > button {
            color: #204569;

            &:first-of-type {
                margin-right: 8px;
            }
            &:last-of-type {
                margin-left: 8px;
            }
        }
    }
}
.cart_buttons {
    text-align: left;
    > button {
        height: 48px;
        width: 180px;
        border-radius: 4px;
        background-color: #3796f6;
        color: #ffffff;
        font-size: 14px;
        text-transform: uppercase;
        font-family: "MontBold";
        padding: 0;
    }
}
.suggested-wrapper {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
    padding: 32px 40px;
    .suggested1 {
        width: 50% !important;
    }
    .suggested2 {
        width: 25% !important;
    }
}
.cart_item_title {
    color: #204569;
    font-size: 10px;
    opacity: 0.5;
}
.cart_item_name {
    margin-left: 24px;
}
.activity_block {
    background: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
    padding: 32px 40px;
    border-radius: 10px;
    .panel_activity {
        padding-bottom: 12px;
    }
    .activity_info_wrapper {
        > div {
            > div {
                border-bottom: 1px solid rgba(32, 69, 105, 0.25);
                display: flex;
                padding-top: 20px;
                flex-flow: column;
                > span {
                    opacity: 0.5;
                    font-family: "MontLight", sans-serif;
                    font-size: 10px;
                    line-height: 13px;
                    padding-bottom: 8px;
                    display: flex;
                }
                p {
                    padding-bottom: 20px;
                    font-size: 14px;
                    span {
                        font-family: "MontSemiBold", sans-serif;
                    }
                }
            }
        }
        > div:last-of-type {
            > div {
                border-bottom: none;
            }
        }
    }
}

.cart-page {
    min-height: calc(100vh - 240px);
}
