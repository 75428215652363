.default_button_wrapper {
    .default_button {
        text-align: center;
        display: flex;
        justify-content: center;
        font-family: "MontBold", sans-serif;
        font-size: 14px;
        height: 48px;
        width: 176px;
        border-radius: 4px;
        text-transform: uppercase;
        box-shadow: 0 4px 8px 0 rgba(24, 113, 204, 0.25);
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        &:disabled {
            opacity: 0.7;
            box-shadow: none;
        }
        .Mui-disabled {
            opacity: 0.7;
            box-shadow: none;
        }
        &.default_button_contained {
            background-color: #3796f6;
            color: #ffffff;
            border: 1px solid #3796f6;
        }
        &.default_button_outlined {
            color: #ffffff;
            border: 1px solid #ffffff;
            &.cancel_btn {
                border: 1px solid #7f8fa4;
                color: #7f8fa4;
            }
            &.blue_btn {
                border: 1px solid #3796f6;
                color: #3796f6;
                &:hover {
                    background: rgba(55, 150, 246, 0.1);
                }
            }

            &.delete_btn {
                border: 1px solid #d0021b;
                color: #d0021b;
            }
        }
    }
    .full_btn_width {
        width: 100%;
    }
    .Mui-disabled {
        opacity: 0.7;
        box-shadow: none;
    }
}

//button:disabled{
//  background: grey;
//  opacity: 0.65;
//  cursor: not-allowed;
//}
