.btn_whats_app{
  height: 64px;
  width: 64px;
  background-color: #2CC197;
  box-shadow: 0 4px 8px 0 rgba(25,86,72,0.25);
  position: fixed;
  right: 32px;
  border-radius: 50%;
  bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.dialog_whats_app{
  display: flex;
  flex-direction: column;
  width: 100%;
  .title{
    margin-bottom: 32px;
    color: #204569;
    font-family: "MontSemiBold",sans-serif;
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 42px;
  }
  a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    margin-bottom: 12px;
    padding: 0 24px 0 32px;
    width: 100%;
    color: #204569;
    font-family: "MontSemiBold",sans-serif;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid rgba(54, 78, 101, 0.25);
    border-radius: 6px;
    text-decoration: none;
  }

}
