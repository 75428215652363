.product-card {
    height: 384px;
    width: 304px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
    padding: 24px;
    margin-bottom: 32px;
    margin-right: 32px;
    margin-bottom: 32px;
    text-decoration: none !important;
    position: relative;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
        transform: scale(1.05);
    }
    .name {
        color: #204569;
        font-family: "MontSemiBold";
        font-size: 14px;
        margin-bottom: 8px;
        max-height: 38px;
        overflow: hidden;
        line-height: 18px;
    }
    .description {
        font-family: "MontLight";
        color: #204569;
        font-size: 10px;
        max-height: 32px;
        overflow: hidden;
        margin-bottom: 16px;
        // line-height: 14px;
    }
    > img {
        height: 192px;
        width: 256px;
        object-fit: cover;
        margin-bottom: 16px;
    }
    .row-wrapper {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 24px;
        width: 256px;
        justify-content: space-between;
        > span {
            color: #fda45c;
            font-family: "MontBold";
            font-size: 14px;
            margin-right: 10px;
        }
        > div {
            display: flex;
            align-items: center;
        }
        .input-wrapper {
            display: flex;
            align-items: center;
            margin-right: 16px;
            > input {
                width: 42px;
                height: 28px;
                border: 1px solid #dee4e9;
                border-radius: 4px;
                text-align: center;
                font-size: 14px;
                font-family: "MontRegular";
                color: #204569;
            }
            > button {
                font-family: "MontRegular";
                color: #204569;
                font-size: 14px;
                &:disabled {
                    opacity: 0.3;
                }
                &:first-of-type {
                    margin-right: 8px;
                }
                &:last-of-type {
                    margin-left: 8px;
                }
            }
        }
        .add-cart {
            height: 36px;
            width: 62px;
            border-radius: 4px;
            background-color: #ebf4fe;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            color: #3796f6;
            font-family: "MontBold";
            text-transform: uppercase;
            > img {
                margin-right: 2px;
            }
        }
    }
}
