.select_component {
  height: 100%;
  & ~ .date_wrapper {
    margin-left: 20px;
  }
  .select__control--is-focused {
    box-shadow: none;
    border: 1px solid #65A3E8;
    .indicator_active {
      svg {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }


  .select__control {
    height: 100%;
    background-color: #ffffff;
    color: #204569!important;
    font-family: "MontRegular",sans-serif!important;
    font-size: 14px;
    border: 1px solid rgba(32,69,105,0.3);
    border-radius: 4px;
    &:hover {
      cursor: pointer;
      border: 1px solid rgba(32,69,105,0.3);
    }
    .select__placeholder {
      color: rgba(127, 143, 164, 0.5);
      //color: #7F8FA4;
    }
    .select__single-value {
      color: #204569;
      margin: 0!important;
      padding-left: 8px!important;
    }
    .select__indicator-separator {
      background: none;
    }
    .select_indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        transition: all 0.3s linear;
        fill: #204569;
        font-size: 14px;
      }
    }
  }
  .select__menu {
    z-index: 9999;
    border-radius: 4px!important;
    box-shadow: 0 4px 8px 0 rgba(154,166,178,0.25)!important;
    border: none!important;
    .select__menu-list {
      font-size: 12px;
      font-family: "MontRegular",sans-serif!important;
      color: #204569;
      background: transparent;
      .select__option{
        background: transparent;
        color: #204569;
        cursor: pointer;
        &:hover{
          background-color: rgba(32,69,105,0.05);
          color: #204569;
        }
      }
      .select__option--is-selected {
        color: #204569;
        font-family: "MontSemiBold",sans-serif!important;
      }
      .status_select{
        padding: 0 12px;
        display: flex;
        align-items: center;
        background: transparent;
        &:hover{
          background: transparent;
        }
      }

    }
  }
  input{
    height: unset;
  }
}

.select_wrapper{
  height: 40px;
  width: 254px;
}
.status{
  display: flex;
  align-items: center;
  .up{
    margin-right: 10px;
  }
  .down{
    margin-right: 10px;
    transform: rotate(180deg);
  }
  span{
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 3px;
  }
}



