.block-input{
  display: block;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  .error{
    display: none;
  }
  &.error_border,
  &.value.error_border {
    position: relative;
    input {
      border: 1px solid #E44B4B !important;
      color: #E44B4B !important;
    }
    .error {
      bottom: -17px;
      display: block;
      color: #E44B4B;
      position: absolute;
      left: 0;
      font-size: 10px;
      line-height: 13px;
    }
  }
  input{
    font-family: "MontRegular",sans-serif;
    background: #fff;
    display: block;
    height: 48px;
    width: 100%;
    font-size: 14px;
    padding: 12px 16px;
    color: #204569;
    border-radius: 4px;
    border: 1px solid rgba(32, 69, 105, 0.3);
    box-sizing: border-box;
    &:focus{
      border: 1px solid #204569;
    }
    &::placeholder{
      font-size: 14px;
      color: rgba(32, 69, 105, 0.5);
      line-height: 20px;
    }
  }
}

