.monthly_overview__chart {
    display: flex;
    align-items: center;
    width: 471px;

    .pie_chart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 180px;
        background-size: cover;

        &_inner {
            position: absolute;
        }

        &__icon {
            max-width: 100px;
            max-height: 100px;
        }

        &__title {
            font-family: "MontSemiBold";
            font-size: 32px;
            color: #204569;
            text-align: center;
            margin-bottom: 0;
        }

        &__info {
            font-family: "MontLight";
            font-size: 16px;
            color: #204569;
            text-align: center;
            margin-bottom: 0;
            opacity: 0.5;
        }
    }

    .companies_list {
        margin-left: 22px;
        margin-bottom: 0;
        &__item {
            display: flex;
            align-items: center;
            margin-top: 16px;
            font-family: "MontRegular";
            font-size: 14px;
            color: #204569;
            &__content {
                margin-bottom: 0;
                display: flex;
                align-items: center;
            }
            &_name {
                width: 135px !important;
                display: flex;
                text-decoration: none;
                color: #204569;
                font-size: 14px;
                font-family: "MontRegular";
                line-height: 14px;
            }
            &_price {
                font-family: "MontSemiBold";
                font-size: 14px;
                color: #204569;
                margin-left: 5px;
            }
        }
        &__item:first-of-type {
            margin-top: 0;
        }

        &__part {
            margin-right: 15px;
            height: 16px;
            width: 16px;
            background-color: #5ef3c9;
            border-radius: 4px;
        }
    }
}

.currency-rotate {
    transform: rotate(270deg);
    font-family: "MontRegular";
    font-size: 12px;
    color: #204569;
}

.no-value {
    font-family: "MontRegular";
    font-size: 28px;
    color: #204569;
}