.user_management_page{
  display: flex;
  flex-flow: column;
  .content_page{
    background: #ffffff;
    padding: 36px 40px;
    margin-bottom: 72px;
    box-shadow: 0 4px 16px 0 rgba(154,166,178,0.15);
    border-radius: 10px;
  }
  .table_panel{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    button{
      margin-right: 24px;
      color: #3796F6;
      text-transform: uppercase;
      font-size: 10px;
      font-family: "MontSemiBold",sans-serif;
    }
  }
  .user_management_table{
    .transactions_columns {
      .table_row {
        & > :first-child {
          width: 30%;
        }
        & > :nth-child(2) {
          width: 38%;
        }
        & > :nth-child(3) {
          width: 22%;
        }
        & > :last-child {
          width: 10%;
          button{
            margin-right: 24px;
            text-transform: uppercase;
            font-family: "MontSemiBold",sans-serif;
            font-size: 10px;
            &:hover{
              opacity: 0.7;
            }
          }
        }
      }
      .cap{
        color: #9aa6b2;
        font-size: 14px;
        font-family: "MontSemiBold",sans-serif;
      }
    }
  }
}

.edit_dialog_user{
  display: flex;
  flex-flow: column;
  width: 100%;
  .title{
    font-size: 32px;
    font-family: "MontSemiBold",sans-serif;
    letter-spacing: -1px;
    line-height: 42px;
    padding-bottom: 32px;
  }
  .block_edit_field{
    display: flex;
    flex-flow: column;
    .span{
      font-family: "MontSemiBold",sans-serif;
      font-size: 10px;
      line-height: 13px;
      padding-bottom: 8px;
      .username{
        font-family: "Mont SemiBold", sans-serif;
      }
    }
    input{
      padding: 0 16px;
      box-sizing: border-box;
      border: 1px solid rgba(32, 69, 105, 0.3);
      border-radius: 4px;
      height: 48px;
      font-family: "MontRegular",sans-serif;
      font-size: 14px;
      &::placeholder{
        color:  rgba(32, 69, 105, 0.5);
      }
    }

  }
  .block_edit_field_select{
    display: flex;
    >div{
      display: flex;
      flex-flow: column;
      &:first-child{
        width: 100%;
        margin-right: 32px;
      }
      >span{
        font-family: "MontSemiBold",sans-serif;
        font-size: 10px;
        line-height: 13px;
        padding-bottom: 8px;
        display: flex;
        justify-content: space-between;
      }
      >.input{
        margin-right: 32px;
        width: 352px;
        padding: 12px 16px;
        box-sizing: border-box;
        border: 1px solid rgba(32, 69, 105, 0.3);
        border-radius: 4px;
        height: 48px;
        font-family: "MontRegular",sans-serif;
        font-size: 14px;
        &::placeholder{
          color:  rgba(32, 69, 105, 0.5);
        }
      }
      .select_wrapper{
        height: 48px;
        width: 208px;
      }
    }
  }
  .btn_wrapper{
    display: flex;
    padding-top: 32px;
    button{
      margin-right: 16px;
    }
  }
  .back_error.add{
    left: unset ;
    bottom: 120px;
    background-color: #FFFFFF;
  }
  .back_error.role{
    left: unset;
    bottom: 105px;
    width: 208px;
  }
}


.delete_dialog{
  display: flex;
  flex-flow: column;
  .title{
    padding-bottom: 32px;
    font-family: "MontSemiBold",sans-serif;
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 42px;
  }
  .descriptions{
    font-size: 14px;
    line-height: 22px;
  }
  .btn_wrapper{
    display: flex;
    padding-top: 32px;
    button{
      margin-right: 16px;
    }
  }
}


