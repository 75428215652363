@font-face {
  font-family: "MontLight";
  src: url(/static/media/Mont-Light.882b5873.ttf) format("truetype"); }

@font-face {
  font-family: "MontBold";
  src: url(/static/media/Mont-Bold.f9808a8b.ttf) format("truetype"); }

@font-face {
  font-family: "MontSemiBold";
  src: url(/static/media/Mont-SemiBold.f22588c7.ttf) format("truetype"); }

@font-face {
  font-family: "MontRegular";
  src: url(/static/media/Mont-Regular.bb1f634a.ttf) format("truetype"); }

@font-face {
  font-family: "FontAwesome";
  src: url(/static/media/FontAwesome.3b15120c.otf) format("OpenType"); }

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, font, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, meter, nav, object, ol, output, p, pre, progress, q, rp, rt, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%; }

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block; }

b, strong {
  font-weight: bold; }

img {
  color: transparent;
  font-size: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic; }

li {
  display: list-item;
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th, td, caption {
  font-weight: normal;
  vertical-align: top;
  text-align: left; }

q {
  quotes: none; }

q:before, q:after {
  content: '';
  content: none; }

sub, sup, small {
  font-size: 75%; }

sub, sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

svg {
  overflow: hidden; }

* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none; }

.clear:after, .clear:before {
  clear: both;
  display: block;
  zoom: 1;
  content: ''; }

body {
  color: #204569;
  overflow: auto;
  box-sizing: border-box;
  background: #ebf4fe;
  font-family: "Montserrat", sans-serif; }
  body::-webkit-scrollbar {
    width: 10px;
    height: 10px; }
  body::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    background: #edf2f8; }
  body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: grey; }
  body::-webkit-scrollbar-thumb:window-inactive {
    background: grey; }

.content_block {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 48px 64px 89px; }

.title_page {
  padding-bottom: 32px;
  color: #204569;
  font-family: "MontSemiBold", sans-serif;
  font-size: 32px;
  letter-spacing: -1px;
  line-height: 42px; }

textarea::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

textarea::-webkit-scrollbar-track {
  -webkit-border-radius: 2px;
  background: #edf2f8; }

textarea::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: grey; }

textarea::-webkit-scrollbar-thumb:window-inactive {
  background: grey; }

a,
button {
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  background: transparent; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

input[type="number"] {
  -moz-appearance: textfield; }

.page {
  display: flex; }

button.green_text {
  margin-right: 24px;
  text-transform: uppercase;
  font-family: "MontSemiBold", sans-serif;
  font-size: 10px;
  color: #2cc197; }
  button.green_text:hover {
    opacity: 0.7; }

.red_text {
  color: #e44b4b; }

.blue_text {
  color: #3796f6; }

.table_container .table_header .table_header {
  display: flex; }

.cancel_btn {
  height: 36px;
  width: 104px;
  border-radius: 2px;
  border: 1px solid #9aa6b2;
  display: flex;
  color: #9aa6b2;
  font-family: "MontSemiBold", sans-serif;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-transform: uppercase; }
  .cancel_btn:hover {
    background: #9aa6b2;
    color: #ffffff; }

.red_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 36px;
  border-radius: 2px;
  font-family: "MontSemiBold", sans-serif;
  width: 104px;
  font-size: 10px;
  color: #e44b4b;
  text-transform: uppercase;
  border: 1px solid #e44b4b; }
  .red_btn:hover {
    background: #e44b4b;
    color: #ffffff; }
  .red_btn:disabled {
    opacity: 0.5; }

h3 {
  color: #9aa6b2;
  font-size: 14px;
  font-family: "MontSemiBold", sans-serif; }

.green_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 36px;
  border-radius: 2px;
  font-family: "MontSemiBold", sans-serif;
  width: 104px;
  font-size: 10px;
  color: #2cc197;
  text-transform: uppercase;
  border: 1px solid #2cc197; }
  .green_btn:hover {
    background: #2cc197;
    color: #ffffff; }

.blue_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 36px;
  border-radius: 2px;
  font-family: "MontSemiBold", sans-serif;
  width: 104px;
  font-size: 10px;
  color: #3796f6;
  text-transform: uppercase;
  border: 1px solid #3796f6; }
  .blue_btn:disabled {
    opacity: 0.5; }
  .blue_btn:hover {
    background: #3796f6;
    color: #ffffff; }

.unactive_btn {
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 36px;
  border-radius: 2px;
  font-family: "MontSemiBold", sans-serif;
  width: 104px;
  font-size: 10px;
  color: #9aa6b2;
  text-transform: uppercase;
  border: 1px solid #9aa6b2; }
  .unactive_btn:hover {
    cursor: not-allowed; }

.table_header .row_item {
  color: rgba(32, 69, 105, 0.5);
  font-size: 10px;
  font-family: "MontLight", sans-serif; }

.table_header .table_row {
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px; }

.table_body .row_item {
  color: #204569;
  font-size: 14px; }

.table_body .table_row {
  width: 100%;
  display: flex;
  align-items: center;
  height: 58px;
  border-top: 1px solid rgba(32, 69, 105, 0.25);
  text-decoration: none; }

button:focus {
  outline: none !important; }

.items_no {
  padding-top: 20px; }

.fix_link {
  display: flex;
  align-items: center;
  justify-content: center; }
  .fix_link img {
    background-size: 100% 100%;
    width: 60%;
    height: 60%; }

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #3796f6 !important; }

.Toastify__toast {
  border-radius: 8px !important; }
  .Toastify__toast-container {
    z-index: 140 !important; }
  .Toastify__toast-body {
    font-family: "Montserrat", sans-serif;
    font-weight: 550;
    font-size: 16px;
    color: #221e1f; }

.Toastify__progress-bar {
  background: #3796f6 !important; }

.red-progress {
  background: red !important; }

ul,
ol {
  padding-left: 40px; }

ul li {
  list-style: initial; }

ol li {
  list-style: decimal; }

.header_wrapper {
  z-index: 100;
  border-bottom: 1px solid #ebf4fe;
  height: 64px;
  width: 100%;
  border-bottom: 1px solid rgba(55, 150, 246, 0.1);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 64px;
  box-sizing: border-box; }
  .header_wrapper .logo_head {
    max-width: 94px;
    margin-right: 64px; }
    .header_wrapper .logo_head > img {
      width: 100%; }
  .header_wrapper .name-wrapper {
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto; }
    .header_wrapper .name-wrapper .name {
      color: #204569;
      font-size: 14px;
      font-family: "MontRegular";
      white-space: nowrap; }
    .header_wrapper .name-wrapper .email {
      font-size: 10px;
      color: #204569;
      font-family: "MontLight";
      text-align: end; }
  .header_wrapper .dropdown-wrapper {
    position: relative; }
    .header_wrapper .dropdown-wrapper > img {
      cursor: pointer; }
  .header_wrapper .cart-wrapper {
    position: relative; }
    .header_wrapper .cart-wrapper .shopping-cart {
      width: 24px;
      margin-right: 32px;
      cursor: pointer; }
    .header_wrapper .cart-wrapper .notif-cart {
      width: 24px;
      margin-right: 16px;
      cursor: pointer; }
    .header_wrapper .cart-wrapper .cart-count {
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #3796F6;
      font-family: "MontBold", sans-serif;
      color: #ffffff;
      top: -1px;
      left: 11px;
      letter-spacing: -0.25px;
      border: 1px solid #ffffff;
      font-size: 8px;
      font-weight: bold;
      padding: 2px 1px 0 0;
      display: flex;
      justify-content: center;
      align-items: center; }
  .header_wrapper .drop-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    width: 192px;
    border-radius: 4px;
    z-index: 100;
    padding: 16px 24px;
    top: 37px;
    right: 0px; }
    .header_wrapper .drop-menu > a {
      color: #204569;
      font-size: 12px;
      font-family: "MontRegular";
      text-decoration: none;
      margin-bottom: 16px; }
    .header_wrapper .drop-menu > div {
      font-family: "MontRegular";
      color: #8fa2b4;
      font-size: 12px;
      cursor: pointer;
      border-top: 1px solid rgba(32, 69, 105, 0.15);
      padding-top: 16px; }
  .header_wrapper .singin,
  .header_wrapper .singup {
    border-radius: 4px;
    background-color: #ebf4fe;
    height: 40px;
    width: 98px;
    color: #3796f6;
    font-family: "MontBold";
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.5s;
    box-sizing: border-box; }
  .header_wrapper .singin {
    margin-right: 12px;
    transition: all 0.5s; }
    .header_wrapper .singin:hover {
      border: 1px solid #3796f6; }
  .header_wrapper .singup {
    background-color: #3796f6;
    color: #ffffff; }
    .header_wrapper .singup:hover {
      background-color: #ebf4fe;
      color: #3796f6;
      border: 1px solid #3796f6; }
  .header_wrapper .logout {
    margin-left: 16px; }
    .header_wrapper .logout:hover {
      -webkit-transform: scale(0.9);
              transform: scale(0.9); }

.search-wrapper {
  border: 1px solid #dee4e9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 40px;
  position: relative;
  width: 100%;
  margin-right: 65px; }
  .search-wrapper .filter-props {
    position: absolute;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    z-index: 100;
    top: 42px; }
    .search-wrapper .filter-props > div {
      color: #204569;
      font-size: 12px;
      font-family: "MontRegular";
      margin-bottom: 10px;
      cursor: pointer;
      display: flex;
      align-items: center; }
      .search-wrapper .filter-props > div:hover {
        font-size: 12px;
        font-family: "MontSemiBold";
        color: #204569; }
    .search-wrapper .filter-props > div:last-of-type {
      margin-bottom: 0; }
    .search-wrapper .filter-props .activeFilter {
      font-size: 12px;
      font-family: "MontSemiBold";
      color: #204569;
      display: flex;
      align-items: center; }
      .search-wrapper .filter-props .activeFilter > img {
        margin-left: 4px; }
  .search-wrapper .search-props {
    position: absolute;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    z-index: 100;
    top: 42px;
    left: 60px; }
    .search-wrapper .search-props > div {
      color: #204569;
      font-size: 12px;
      font-family: "MontRegular";
      margin-bottom: 10px;
      cursor: pointer; }
      .search-wrapper .search-props > div:hover {
        font-size: 12px;
        font-family: "MontSemiBold";
        color: #204569; }
    .search-wrapper .search-props > div:last-of-type {
      margin-bottom: 0; }
  .search-wrapper .filter {
    display: flex;
    align-items: center;
    background-color: #ebf4fe;
    height: 100%;
    padding: 9px 12px;
    cursor: pointer; }
    .search-wrapper .filter .reverse {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
    .search-wrapper .filter > span {
      font-size: 14px;
      color: #204569;
      font-family: "MontSemiBold";
      height: 17px;
      vertical-align: middle;
      margin-right: 4px;
      white-space: nowrap; }
  .search-wrapper input {
    color: #204569;
    font-size: 14px;
    font-family: "MontRegular";
    height: 100%;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%; }

.panel_wrapper {
  border-right: 2px solid rgba(55, 150, 246, 0.1);
  max-width: 158px;
  margin-top: -80px;
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  min-height: 100vh; }

.panel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center; }
  .panel .logo_panel {
    margin-top: 32px;
    margin-bottom: 68px; }
    .panel .logo_panel img {
      background-size: 100% 100%;
      height: 28px;
      width: 94px; }
  .panel .block_link {
    display: flex;
    flex-flow: column; }
    .panel .block_link a {
      width: 64px;
      height: 64px;
      background: rgba(54, 78, 101, 0.2);
      font-size: 18px;
      border-radius: 10px;
      text-decoration: none;
      margin-bottom: 45px;
      position: relative; }
      .panel .block_link a .panel_title {
        position: absolute;
        top: 100%;
        left: 50%;
        -webkit-transform: translate(-50%, 5px);
                transform: translate(-50%, 5px);
        text-align: center;
        font-size: 12px;
        line-height: 14px;
        color: #444;
        transition: color 200ms; }
      .panel .block_link a:hover {
        background: #3796f6; }
        .panel .block_link a:hover .panel_title {
          color: #222; }
      .panel .block_link a .cart-count {
        position: absolute;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: #c32e45;
        font-family: "Rubik", sans-serif;
        font-size: 12px;
        color: #ffffff;
        top: -7px;
        right: -7px;
        display: flex;
        justify-content: center;
        align-items: center; }
    .panel .block_link .active {
      box-shadow: 0 4px 8px 0 rgba(24, 113, 204, 0.25);
      background: #3796f6; }
      .panel .block_link .active .panel_title {
        color: #000; }

.catalog-card {
  height: 384px;
  width: 304px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
  padding: 24px;
  text-decoration: none !important;
  transition: all 0.5s; }
  .catalog-card:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .catalog-card > p {
    color: #204569;
    font-family: "MontSemiBold";
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
    overflow: hidden;
    width: 256px; }
  .catalog-card > img {
    height: 256px;
    width: 256px;
    margin-top: 16px;
    object-fit: cover; }
  .catalog-card > a {
    color: #3796f6;
    margin-top: 24px;
    font-family: "MontSemiBold";
    text-transform: uppercase;
    font-size: 10px;
    display: flex;
    text-decoration: none;
    align-items: center; }
    .catalog-card > a > img {
      margin-left: 4px; }

.main-card {
  margin: 0 16px;
  margin-bottom: 32px; }

.category-card {
  margin-right: 32px;
  margin-bottom: 32px; }

.catalog_page {
  display: flex;
  flex-flow: column;
  width: 100%; }
  .catalog_page .slider-wrapper {
    width: 100%;
    height: 244px;
    margin-bottom: 32px; }
    .catalog_page .slider-wrapper .slick-next {
      right: 64px;
      background: url(/static/media/forward.0233a3fb.svg) no-repeat;
      width: 48px;
      height: 48px; }
      .catalog_page .slider-wrapper .slick-next:before {
        content: ""; }
    .catalog_page .slider-wrapper .slick-prev {
      left: 64px;
      background: url(/static/media/back.66d448c7.svg) no-repeat;
      width: 48px;
      height: 48px;
      z-index: 1; }
      .catalog_page .slider-wrapper .slick-prev:before {
        content: ""; }
    .catalog_page .slider-wrapper .slider-el {
      height: 100%;
      background-color: rgba(32, 69, 105, 0.25);
      height: 244px; }
      .catalog_page .slider-wrapper .slider-el > img {
        height: 244px;
        object-fit: cover;
        width: 100%; }
    .catalog_page .slider-wrapper .slick-slide > div {
      height: 244px; }
  .catalog_page .catalog-wrapper {
    display: flex;
    justify-content: center;
    width: 100%; }
    .catalog_page .catalog-wrapper .cards-wrapper {
      display: flex;
      flex-wrap: wrap;
      max-width: 1344px; }
  .catalog_page .content_page {
    background: #ffffff;
    padding: 36px 40px;
    margin-bottom: 72px;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
    border-radius: 10px; }
  .catalog_page .table_panel {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .catalog_page .table_panel button {
      margin-right: 24px;
      color: #3796f6;
      text-transform: uppercase;
      font-size: 10px;
      font-family: "MontSemiBold", sans-serif; }
  .catalog_page .custom_title_wrapper .title_page {
    padding-bottom: 32px; }
  .catalog_page .catalog_table .transactions_columns .table_row > :first-child {
    width: 35%; }
    .catalog_page .catalog_table .transactions_columns .table_row > :first-child a {
      font-family: "MontSemiBold", sans-serif;
      color: #3796f6; }
  .catalog_page .catalog_table .transactions_columns .table_row > :nth-child(2) {
    width: 20.5%; }
  .catalog_page .catalog_table .transactions_columns .table_row > :nth-child(3) {
    width: 20.5%; }
  .catalog_page .catalog_table .transactions_columns .table_row > :nth-child(4) {
    width: 12%; }
  .catalog_page .catalog_table .transactions_columns .table_row > :nth-child(5) {
    width: 12%; }
    .catalog_page .catalog_table .transactions_columns .table_row > :nth-child(5) button {
      margin-right: 24px;
      text-transform: uppercase;
      font-family: "MontSemiBold", sans-serif;
      font-size: 10px; }
      .catalog_page .catalog_table .transactions_columns .table_row > :nth-child(5) button:hover {
        opacity: 0.7; }
  .catalog_page .catalog .transactions_columns .table_row > :first-child {
    width: 37%; }
  .catalog_page .catalog .transactions_columns .table_row > :nth-child(2) {
    width: 26%; }
  .catalog_page .catalog .transactions_columns .table_row > :nth-child(3) {
    width: 26%; }
  .catalog_page .catalog .transactions_columns .table_row > :last-child {
    width: 11%; }

.delete_dialog {
  display: flex;
  flex-flow: column; }
  .delete_dialog .title {
    padding-bottom: 32px;
    font-family: "MontSemiBold", sans-serif;
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 42px; }
  .delete_dialog .descriptions {
    font-size: 14px;
    line-height: 22px; }
    .delete_dialog .descriptions > span > span {
      font-family: "MontSemiBold", sans-serif !important; }
  .delete_dialog .btn_wrapper {
    display: flex;
    padding-top: 32px; }
    .delete_dialog .btn_wrapper button {
      margin-right: 16px; }

.edit_dialog {
  display: flex;
  flex-flow: column;
  width: 100%; }
  .edit_dialog .title {
    font-size: 32px;
    font-family: "MontSemiBold", sans-serif;
    letter-spacing: -1px;
    line-height: 42px;
    padding-bottom: 32px; }
  .edit_dialog .block_edit_field {
    display: flex; }
    .edit_dialog .block_edit_field > div {
      display: flex;
      flex-flow: column; }
      .edit_dialog .block_edit_field > div span {
        font-family: "MontSemiBold", sans-serif;
        font-size: 10px;
        line-height: 13px;
        padding-bottom: 8px; }
      .edit_dialog .block_edit_field > div input {
        padding: 0 16px;
        box-sizing: border-box;
        border: 1px solid rgba(32, 69, 105, 0.3);
        border-radius: 4px;
        height: 48px;
        font-family: "MontRegular", sans-serif;
        font-size: 14px; }
        .edit_dialog .block_edit_field > div input::-webkit-input-placeholder {
          color: rgba(32, 69, 105, 0.5); }
        .edit_dialog .block_edit_field > div input::placeholder {
          color: rgba(32, 69, 105, 0.5); }
    .edit_dialog .block_edit_field > div:first-of-type input {
      width: 352px;
      margin-right: 32px; }
    .edit_dialog .block_edit_field > div:last-of-type:not(:first-of-type) {
      position: relative; }
      .edit_dialog .block_edit_field > div:last-of-type:not(:first-of-type) input {
        width: 208px;
        padding: 0 30px 0px 16px;
        box-sizing: border-box; }
      .edit_dialog .block_edit_field > div:last-of-type:not(:first-of-type) p {
        position: absolute;
        right: 16px;
        top: 36px;
        opacity: 0.5;
        font-size: 14px;
        line-height: 20px; }
  .edit_dialog .btn_wrapper {
    display: flex;
    padding-top: 32px; }
    .edit_dialog .btn_wrapper button {
      margin-right: 16px; }

.stock_dialog {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .stock_dialog > * {
    width: 100%; }
    .stock_dialog > *.stock_wrapper > .first_block {
      width: calc(50% - 8px); }
      .stock_dialog > *.stock_wrapper > .first_block input {
        width: 100%; }
      .stock_dialog > *.stock_wrapper > .first_block:first-child {
        margin-right: 16px; }
  .stock_dialog .title {
    font-size: 32px;
    font-family: "MontSemiBold", sans-serif;
    letter-spacing: -1px;
    line-height: 42px;
    padding-bottom: 32px; }
  .stock_dialog .help_block {
    padding-bottom: 30px;
    font-size: 14px; }
  .stock_dialog .btn_wrapper {
    display: flex;
    padding-top: 32px; }
    .stock_dialog .btn_wrapper button {
      margin-right: 16px; }
  .stock_dialog .stock_wrapper {
    display: flex; }
    .stock_dialog .stock_wrapper .first_block {
      margin-left: 0; }
    .stock_dialog .stock_wrapper .supply_btn {
      display: flex; }
      .stock_dialog .stock_wrapper .supply_btn button {
        border-radius: 4px;
        background: transparent;
        opacity: 0.3;
        margin-right: 12px; }
      .stock_dialog .stock_wrapper .supply_btn .active {
        opacity: 1; }
    .stock_dialog .stock_wrapper .disabled {
      position: relative; }
      .stock_dialog .stock_wrapper .disabled:before {
        position: absolute;
        content: "";
        width: 124px;
        height: 100%;
        left: 0;
        top: 0;
        background: transparent;
        z-index: 100; }
      .stock_dialog .stock_wrapper .disabled span {
        opacity: 0.3; }
      .stock_dialog .stock_wrapper .disabled input {
        opacity: 0.3; }
    .stock_dialog .stock_wrapper > div {
      margin-left: 32px; }
      .stock_dialog .stock_wrapper > div span {
        display: flex;
        padding-bottom: 8px;
        font-family: "MontSemiBold", sans-serif;
        font-size: 10px;
        line-height: 13px; }
      .stock_dialog .stock_wrapper > div input {
        padding: 0 16px;
        box-sizing: border-box;
        border: 1px solid rgba(32, 69, 105, 0.3);
        border-radius: 4px;
        height: 48px;
        width: 124px;
        font-family: "MontRegular", sans-serif;
        font-size: 14px; }
        .stock_dialog .stock_wrapper > div input::-webkit-input-placeholder {
          color: rgba(32, 69, 105, 0.5); }
        .stock_dialog .stock_wrapper > div input::placeholder {
          color: rgba(32, 69, 105, 0.5); }

.add_product_dialog {
  display: flex;
  flex-flow: column;
  width: 100%; }
  .add_product_dialog .title {
    font-size: 32px;
    font-family: "MontSemiBold", sans-serif;
    letter-spacing: -1px;
    line-height: 42px;
    padding-bottom: 32px; }
  .add_product_dialog .descriptions {
    padding-bottom: 24px;
    font-size: 14px;
    line-height: 22px; }
  .add_product_dialog .btn_wrapper {
    display: flex;
    padding-top: 32px; }
    .add_product_dialog .btn_wrapper button {
      margin-right: 16px; }

.block_search .input-group .select_wrapper {
  margin-right: 20px; }

.block_search .input-group .search_info {
  margin-left: -1px;
  position: relative; }
  .block_search .input-group .search_info input {
    box-sizing: border-box;
    height: 40px;
    width: 254px;
    border: 1px solid rgba(32, 69, 105, 0.3);
    border-radius: 4px;
    background-color: #ffffff;
    padding: 0 16px 0 35px;
    font-family: "MontRegular", sans-serif;
    position: relative;
    color: #204569; }
    .block_search .input-group .search_info input::-webkit-input-placeholder {
      opacity: 0.5;
      color: #204569;
      font-size: 14px; }
    .block_search .input-group .search_info input::placeholder {
      opacity: 0.5;
      color: #204569;
      font-size: 14px; }
    .block_search .input-group .search_info input:disabled {
      opacity: 0.5; }
  .block_search .input-group .search_info:before {
    position: absolute;
    content: "";
    left: 12px;
    top: calc(50% - 7px);
    background: url(/static/media/search.e80071e9.svg);
    height: 14px;
    width: 14px;
    z-index: 1; }

.block_search .autocomplete {
  display: flex;
  flex-flow: column;
  position: absolute;
  left: 0;
  top: 51px;
  box-shadow: 0 4px 8px 0 rgba(154, 166, 178, 0.25);
  border-radius: 4px;
  max-height: 300px;
  padding: 12px 0;
  box-sizing: border-box;
  width: 254px;
  overflow: auto;
  background: #fff;
  z-index: 1; }
  .block_search .autocomplete::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .block_search .autocomplete::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    background: #ffffff; }
  .block_search .autocomplete::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #204569; }
  .block_search .autocomplete::-webkit-scrollbar-thumb:window-inactive {
    background: #204569; }
  .block_search .autocomplete > div > button {
    color: #204569;
    text-align: inherit;
    background: transparent;
    font-size: 14px;
    line-height: 20px;
    padding: 4px 24px;
    width: 100%;
    display: flex;
    font-family: "MontRegular", sans-serif; }
    .block_search .autocomplete > div > button:focus {
      outline: none; }
  .block_search .autocomplete .new {
    align-items: center;
    font-family: "MontSemiBold", sans-serif; }
    .block_search .autocomplete .new span {
      color: #3796f6;
      font-family: "MontSemiBold", sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      line-height: 16px;
      padding-left: 12px; }
  .block_search .autocomplete .search_item:hover {
    background-color: #d2d2d2;
    border: 2px; }

.add_dialog {
  display: flex;
  flex-flow: column;
  width: 100%; }
  .add_dialog .title {
    font-size: 32px;
    font-family: "MontSemiBold", sans-serif;
    letter-spacing: -1px;
    line-height: 42px;
    padding-bottom: 32px; }
  .add_dialog .block_add_field {
    display: flex;
    justify-content: space-between; }
    .add_dialog .block_add_field > div {
      display: flex;
      flex-flow: column; }
      .add_dialog .block_add_field > div span {
        font-family: "MontSemiBold", sans-serif;
        font-size: 10px;
        line-height: 13px;
        padding-bottom: 8px; }
      .add_dialog .block_add_field > div input {
        padding: 0 16px;
        box-sizing: border-box;
        border: 1px solid rgba(32, 69, 105, 0.3);
        border-radius: 4px;
        height: 48px;
        font-family: "MontRegular", sans-serif;
        font-size: 14px; }
        .add_dialog .block_add_field > div input::-webkit-input-placeholder {
          color: rgba(32, 69, 105, 0.5); }
        .add_dialog .block_add_field > div input::placeholder {
          color: rgba(32, 69, 105, 0.5); }
    .add_dialog .block_add_field > div:first-of-type input {
      width: 352px; }
    .add_dialog .block_add_field > div:last-of-type {
      position: relative; }
      .add_dialog .block_add_field > div:last-of-type input {
        width: 208px;
        padding: 0 30px 0px 16px;
        box-sizing: border-box; }
      .add_dialog .block_add_field > div:last-of-type p {
        position: absolute;
        right: 16px;
        top: 36px;
        opacity: 0.5;
        font-size: 14px;
        line-height: 20px; }
  .add_dialog .btn_wrapper {
    display: flex;
    padding-top: 32px; }
    .add_dialog .btn_wrapper button {
      margin-right: 16px; }

.custom_title_wrapper a {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #3796f6;
  font-family: "MontSemiBold", sans-serif;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase; }
  .custom_title_wrapper a img {
    margin-right: 8px;
    margin-top: -2px; }

.custom_title_wrapper .title_page {
  padding-bottom: 24px; }

.pagination_info_wrapper {
  padding-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .pagination_info_wrapper .info {
    opacity: 0.5;
    color: #204569;
    font-family: "MontLight", sans-serif;
    font-size: 12px;
    line-height: 16px;
    text-align: right; }

h3.empty_list {
  padding: 30px 0;
  border-top: 1px solid rgba(32, 69, 105, 0.25);
  margin-top: 18px; }

.block_field.row.error_block {
  display: flex;
  flex-direction: row;
  height: 13px;
  padding: 8px 0 0; }
  .block_field.row.error_block span {
    color: red;
    font-weight: 900;
    padding: 0; }

#tooltip-top > .tooltip-inner {
  background-color: #fff;
  color: #000;
  width: 400px; }

#tooltip-top > .tooltip-arrow {
  border-top: 5px solid #062e56; }

.selectBtn {
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none; }

.selectBtn,
option {
  min-width: 10px;
  width: auto; }

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #3796f6; }

.popover_text {
  text-align: left;
  -webkit-user-select: text;
          user-select: text; }
  .popover_text::selection {
    background: #b6e7fc; }

.promotional-cart {
  height: 384px;
  width: 304px;
  border-radius: 10px;
  box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
  margin-bottom: 32px;
  margin-left: 16px;
  text-decoration: none !important;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: center/100%;
  background-size: cover;
  transition: all 0.5s; }
  .promotional-cart:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .promotional-cart > p {
    margin-top: 120px;
    color: #c56519;
    font-size: 20px;
    font-family: "MontSemiBold";
    margin-bottom: 32px;
    text-align: center; }
  .promotional-cart > a {
    height: 36px;
    width: 104px;
    border-radius: 4px;
    background-color: #fda45c;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: "MontSemiBold";
    font-size: 10px;
    text-transform: uppercase;
    text-decoration: none !important; }
    .promotional-cart > a:hover {
      color: #ffffff; }

.loaderWrapper {
    min-height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    align-items: center;
}
.dashboard_page {
  display: flex;
  flex-flow: column;
  padding: 48px 64px 85px !important; }
  .dashboard_page .first-row {
    display: flex;
    justify-content: space-between; }
    .dashboard_page .first-row > div {
      margin-left: 33px;
      height: 288px;
      width: 100%;
      padding: 32px 40px;
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15); }
    .dashboard_page .first-row > div:first-of-type {
      margin-left: 0; }
  .dashboard_page .second-row {
    padding: 32px 40px;
    margin-top: 32px;
    height: 330px;
    width: 100%;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15); }
    .dashboard_page .second-row-header {
      margin-bottom: 20px; }
      .dashboard_page .second-row-header > span {
        font-family: "MontSemiBold";
        font-size: 16px;
        cursor: pointer;
        color: #204569;
        opacity: 0.3; }
      .dashboard_page .second-row-header > span:first-of-type {
        margin-right: 24px; }
      .dashboard_page .second-row-header .active-header {
        opacity: 1; }
    .dashboard_page .second-row-content {
      display: flex;
      justify-content: space-between; }
      .dashboard_page .second-row-content > div:first-of-type {
        width: 100%;
        margin-right: 64px; }

.category-header {
  margin-bottom: 20px;
  font-family: "MontSemiBold", sans-serif;
  color: #204569;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px; }

.stock-level {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; }
  .stock-level > p {
    margin-bottom: 0px !important;
    font-family: "MontSemiBold", sans-serif;
    color: #204569;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px; }
  .stock-level > div > span {
    font-family: "MontRegular";
    font-size: 14px;
    cursor: pointer;
    color: #8fa2b4; }
  .stock-level .mm {
    margin-right: 24px; }
  .stock-level .active {
    color: #204569; }

.chart-no-results {
  text-align: center;
  margin-top: 80px;
  font-size: 20px;
  font-family: "MontRegular"; }

.stock-header {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .stock-header > p {
    margin-bottom: 0px !important;
    font-family: "MontSemiBold", sans-serif;
    color: #204569;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px; }

.in-stock,
.low-stock,
.out-stock {
  display: flex;
  align-items: center;
  margin-top: 26px; }
  .in-stock > img,
  .low-stock > img,
  .out-stock > img {
    margin-right: 12px;
    margin-top: 5px; }
  .in-stock > div,
  .low-stock > div,
  .out-stock > div {
    width: 100%; }
  .in-stock-data,
  .low-stock-data,
  .out-stock-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    .in-stock-data .bar-name,
    .low-stock-data .bar-name,
    .out-stock-data .bar-name {
      color: #204569;
      font-size: 14px;
      font-family: "MontRegular";
      line-height: 18px; }
    .in-stock-data .amount,
    .low-stock-data .amount,
    .out-stock-data .amount {
      color: #204569;
      font-family: "MontSemiBold", sans-serif;
      font-size: 18px;
      line-height: 20px;
      margin-right: 6px; }
    .in-stock-data .percents,
    .low-stock-data .percents,
    .out-stock-data .percents {
      opacity: 0.5;
      color: #204569;
      font-family: "MontLight";
      font-size: 12px;
      line-height: 16px; }
  .in-stock-bar,
  .low-stock-bar,
  .out-stock-bar {
    margin-top: 3.5px;
    height: 1px;
    border: 1px solid rgba(32, 69, 105, 0.15); }
    .in-stock-bar-fill,
    .low-stock-bar-fill,
    .out-stock-bar-fill {
      height: 1px;
      background-color: #2cc197; }

.low-stock-bar-fill {
  background-color: #fda45c; }

.out-stock-bar-fill {
  background-color: #e44b4b; }



.highcharts-scrollbar-button,
.highcharts-scrollbar-arrow {
  display: none !important; }

.highcharts-scrollbar-thumb {
  cursor: pointer; }

.monthly_overview__chart {
  display: flex;
  align-items: center;
  width: 471px; }
  .monthly_overview__chart .pie_chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;
    background-size: cover; }
    .monthly_overview__chart .pie_chart_inner {
      position: absolute; }
    .monthly_overview__chart .pie_chart__icon {
      max-width: 100px;
      max-height: 100px; }
    .monthly_overview__chart .pie_chart__title {
      font-family: "MontSemiBold";
      font-size: 32px;
      color: #204569;
      text-align: center;
      margin-bottom: 0; }
    .monthly_overview__chart .pie_chart__info {
      font-family: "MontLight";
      font-size: 16px;
      color: #204569;
      text-align: center;
      margin-bottom: 0;
      opacity: 0.5; }
  .monthly_overview__chart .companies_list {
    margin-left: 22px;
    margin-bottom: 0; }
    .monthly_overview__chart .companies_list__item {
      display: flex;
      align-items: center;
      margin-top: 16px;
      font-family: "MontRegular";
      font-size: 14px;
      color: #204569; }
      .monthly_overview__chart .companies_list__item__content {
        margin-bottom: 0;
        display: flex;
        align-items: center; }
      .monthly_overview__chart .companies_list__item_name {
        width: 135px !important;
        display: flex;
        text-decoration: none;
        color: #204569;
        font-size: 14px;
        font-family: "MontRegular";
        line-height: 14px; }
      .monthly_overview__chart .companies_list__item_price {
        font-family: "MontSemiBold";
        font-size: 14px;
        color: #204569;
        margin-left: 5px; }
    .monthly_overview__chart .companies_list__item:first-of-type {
      margin-top: 0; }
    .monthly_overview__chart .companies_list__part {
      margin-right: 15px;
      height: 16px;
      width: 16px;
      background-color: #5ef3c9;
      border-radius: 4px; }

.currency-rotate {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  font-family: "MontRegular";
  font-size: 12px;
  color: #204569; }

.no-value {
  font-family: "MontRegular";
  font-size: 28px;
  color: #204569; }

.default_dialog_root .paper_custom_dialog {
  border-radius: 6px;
  min-width: 720px;
  width: 100%;
  box-shadow: none; }

.default_dialog_root .dialog_close_button {
  cursor: pointer;
  position: absolute;
  right: 23px;
  top: 23px;
  background: transparent;
  transition: all 0.3s linear; }
  .default_dialog_root .dialog_close_button:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }

.dialog_wrapper {
  padding: 48px 64px;
  display: flex;
  position: relative;
  background-color: #FFFFFF;
  max-height: 850px;
  overflow: auto; }
  .dialog_wrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .dialog_wrapper::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    background: #EDF2F8; }
  .dialog_wrapper::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: grey; }
  .dialog_wrapper::-webkit-scrollbar-thumb:window-inactive {
    background: grey; }

.select_component {
  height: 100%; }
  .select_component ~ .date_wrapper {
    margin-left: 20px; }
  .select_component .select__control--is-focused {
    box-shadow: none;
    border: 1px solid #65A3E8; }
    .select_component .select__control--is-focused .indicator_active svg {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .select_component .select__control {
    height: 100%;
    background-color: #ffffff;
    color: #204569 !important;
    font-family: "MontRegular", sans-serif !important;
    font-size: 14px;
    border: 1px solid rgba(32, 69, 105, 0.3);
    border-radius: 4px; }
    .select_component .select__control:hover {
      cursor: pointer;
      border: 1px solid rgba(32, 69, 105, 0.3); }
    .select_component .select__control .select__placeholder {
      color: rgba(127, 143, 164, 0.5); }
    .select_component .select__control .select__single-value {
      color: #204569;
      margin: 0 !important;
      padding-left: 8px !important; }
    .select_component .select__control .select__indicator-separator {
      background: none; }
    .select_component .select__control .select_indicator {
      display: flex;
      align-items: center;
      justify-content: center; }
      .select_component .select__control .select_indicator svg {
        transition: all 0.3s linear;
        fill: #204569;
        font-size: 14px; }
  .select_component .select__menu {
    z-index: 9999;
    border-radius: 4px !important;
    box-shadow: 0 4px 8px 0 rgba(154, 166, 178, 0.25) !important;
    border: none !important; }
    .select_component .select__menu .select__menu-list {
      font-size: 12px;
      font-family: "MontRegular", sans-serif !important;
      color: #204569;
      background: transparent; }
      .select_component .select__menu .select__menu-list .select__option {
        background: transparent;
        color: #204569;
        cursor: pointer; }
        .select_component .select__menu .select__menu-list .select__option:hover {
          background-color: rgba(32, 69, 105, 0.05);
          color: #204569; }
      .select_component .select__menu .select__menu-list .select__option--is-selected {
        color: #204569;
        font-family: "MontSemiBold", sans-serif !important; }
      .select_component .select__menu .select__menu-list .status_select {
        padding: 0 12px;
        display: flex;
        align-items: center;
        background: transparent; }
        .select_component .select__menu .select__menu-list .status_select:hover {
          background: transparent; }
  .select_component input {
    height: unset; }

.select_wrapper {
  height: 40px;
  width: 254px; }

.status {
  display: flex;
  align-items: center; }
  .status .up {
    margin-right: 10px; }
  .status .down {
    margin-right: 10px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .status span {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 3px; }

.block-input {
  display: block;
  width: 100%;
  margin-bottom: 30px;
  position: relative; }
  .block-input .error {
    display: none; }
  .block-input.error_border, .block-input.value.error_border {
    position: relative; }
    .block-input.error_border input, .block-input.value.error_border input {
      border: 1px solid #E44B4B !important;
      color: #E44B4B !important; }
    .block-input.error_border .error, .block-input.value.error_border .error {
      bottom: -17px;
      display: block;
      color: #E44B4B;
      position: absolute;
      left: 0;
      font-size: 10px;
      line-height: 13px; }
  .block-input input {
    font-family: "MontRegular",sans-serif;
    background: #fff;
    display: block;
    height: 48px;
    width: 100%;
    font-size: 14px;
    padding: 12px 16px;
    color: #204569;
    border-radius: 4px;
    border: 1px solid rgba(32, 69, 105, 0.3);
    box-sizing: border-box; }
    .block-input input:focus {
      border: 1px solid #204569; }
    .block-input input::-webkit-input-placeholder {
      font-size: 14px;
      color: rgba(32, 69, 105, 0.5);
      line-height: 20px; }
    .block-input input::placeholder {
      font-size: 14px;
      color: rgba(32, 69, 105, 0.5);
      line-height: 20px; }

.user_management_page {
  display: flex;
  flex-flow: column; }
  .user_management_page .content_page {
    background: #ffffff;
    padding: 36px 40px;
    margin-bottom: 72px;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
    border-radius: 10px; }
  .user_management_page .table_panel {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .user_management_page .table_panel button {
      margin-right: 24px;
      color: #3796F6;
      text-transform: uppercase;
      font-size: 10px;
      font-family: "MontSemiBold",sans-serif; }
  .user_management_page .user_management_table .transactions_columns .table_row > :first-child {
    width: 30%; }
  .user_management_page .user_management_table .transactions_columns .table_row > :nth-child(2) {
    width: 38%; }
  .user_management_page .user_management_table .transactions_columns .table_row > :nth-child(3) {
    width: 22%; }
  .user_management_page .user_management_table .transactions_columns .table_row > :last-child {
    width: 10%; }
    .user_management_page .user_management_table .transactions_columns .table_row > :last-child button {
      margin-right: 24px;
      text-transform: uppercase;
      font-family: "MontSemiBold",sans-serif;
      font-size: 10px; }
      .user_management_page .user_management_table .transactions_columns .table_row > :last-child button:hover {
        opacity: 0.7; }
  .user_management_page .user_management_table .transactions_columns .cap {
    color: #9aa6b2;
    font-size: 14px;
    font-family: "MontSemiBold",sans-serif; }

.edit_dialog_user {
  display: flex;
  flex-flow: column;
  width: 100%; }
  .edit_dialog_user .title {
    font-size: 32px;
    font-family: "MontSemiBold",sans-serif;
    letter-spacing: -1px;
    line-height: 42px;
    padding-bottom: 32px; }
  .edit_dialog_user .block_edit_field {
    display: flex;
    flex-flow: column; }
    .edit_dialog_user .block_edit_field .span {
      font-family: "MontSemiBold",sans-serif;
      font-size: 10px;
      line-height: 13px;
      padding-bottom: 8px; }
      .edit_dialog_user .block_edit_field .span .username {
        font-family: "Mont SemiBold", sans-serif; }
    .edit_dialog_user .block_edit_field input {
      padding: 0 16px;
      box-sizing: border-box;
      border: 1px solid rgba(32, 69, 105, 0.3);
      border-radius: 4px;
      height: 48px;
      font-family: "MontRegular",sans-serif;
      font-size: 14px; }
      .edit_dialog_user .block_edit_field input::-webkit-input-placeholder {
        color: rgba(32, 69, 105, 0.5); }
      .edit_dialog_user .block_edit_field input::placeholder {
        color: rgba(32, 69, 105, 0.5); }
  .edit_dialog_user .block_edit_field_select {
    display: flex; }
    .edit_dialog_user .block_edit_field_select > div {
      display: flex;
      flex-flow: column; }
      .edit_dialog_user .block_edit_field_select > div:first-child {
        width: 100%;
        margin-right: 32px; }
      .edit_dialog_user .block_edit_field_select > div > span {
        font-family: "MontSemiBold",sans-serif;
        font-size: 10px;
        line-height: 13px;
        padding-bottom: 8px;
        display: flex;
        justify-content: space-between; }
      .edit_dialog_user .block_edit_field_select > div > .input {
        margin-right: 32px;
        width: 352px;
        padding: 12px 16px;
        box-sizing: border-box;
        border: 1px solid rgba(32, 69, 105, 0.3);
        border-radius: 4px;
        height: 48px;
        font-family: "MontRegular",sans-serif;
        font-size: 14px; }
        .edit_dialog_user .block_edit_field_select > div > .input::-webkit-input-placeholder {
          color: rgba(32, 69, 105, 0.5); }
        .edit_dialog_user .block_edit_field_select > div > .input::placeholder {
          color: rgba(32, 69, 105, 0.5); }
      .edit_dialog_user .block_edit_field_select > div .select_wrapper {
        height: 48px;
        width: 208px; }
  .edit_dialog_user .btn_wrapper {
    display: flex;
    padding-top: 32px; }
    .edit_dialog_user .btn_wrapper button {
      margin-right: 16px; }
  .edit_dialog_user .back_error.add {
    left: unset;
    bottom: 120px;
    background-color: #FFFFFF; }
  .edit_dialog_user .back_error.role {
    left: unset;
    bottom: 105px;
    width: 208px; }

.delete_dialog {
  display: flex;
  flex-flow: column; }
  .delete_dialog .title {
    padding-bottom: 32px;
    font-family: "MontSemiBold",sans-serif;
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 42px; }
  .delete_dialog .descriptions {
    font-size: 14px;
    line-height: 22px; }
  .delete_dialog .btn_wrapper {
    display: flex;
    padding-top: 32px; }
    .delete_dialog .btn_wrapper button {
      margin-right: 16px; }

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent; }

.pagination-list {
  display: flex; }
  .pagination-list li {
    border-radius: 2px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease .3s; }
    .pagination-list li:hover {
      background: rgba(55, 150, 246, 0.2); }
    .pagination-list li a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }

.pagination-link {
  cursor: pointer;
  background: transparent;
  color: #3796F6;
  text-decoration: none;
  text-align: center;
  line-height: 28px;
  height: 24px;
  width: 24px;
  font-size: 14px;
  min-width: 24px; }
  .pagination-link.pagination-link-active {
    border-radius: 2px;
    background: #3796F6;
    color: #ffffff; }
    .pagination-link.pagination-link-active:hover {
      opacity: 0.7; }

.pagination-ellipsis {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; }
  .pagination-ellipsis a {
    cursor: pointer;
    background: transparent;
    height: 24px;
    width: 24px;
    color: #3796F6;
    text-decoration: none;
    text-align: center;
    line-height: 28px; }

.next, .previous {
  margin: 0; }
  .next.disabled:hover, .previous.disabled:hover {
    background: transparent; }
  .next.disabled a, .previous.disabled a {
    opacity: 0.5;
    cursor: not-allowed; }
  .next a, .previous a {
    display: flex;
    align-items: center;
    justify-content: center; }
    .next a svg, .previous a svg {
      font-size: 16px;
      fill: #3796F6; }

.stock_management_page {
  display: flex;
  flex-flow: column; }
  .stock_management_page .content_page {
    background: #ffffff;
    padding: 32px 40px;
    margin-bottom: 72px;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
    border-radius: 10px; }
  .stock_management_page .block_search .input-group .select_wrapper {
    margin-right: 20px; }
  .stock_management_page .block_search .input-group .search_info {
    margin-left: -1px;
    position: relative; }
    .stock_management_page .block_search .input-group .search_info input {
      box-sizing: border-box;
      height: 40px;
      width: 254px;
      border: 1px solid rgba(32, 69, 105, 0.3);
      border-radius: 4px;
      background-color: #ffffff;
      padding: 0 16px 0 35px;
      font-family: "MontRegular", sans-serif;
      position: relative;
      color: #204569; }
      .stock_management_page .block_search .input-group .search_info input::-webkit-input-placeholder {
        opacity: 0.5;
        color: #204569;
        font-size: 14px; }
      .stock_management_page .block_search .input-group .search_info input::placeholder {
        opacity: 0.5;
        color: #204569;
        font-size: 14px; }
    .stock_management_page .block_search .input-group .search_info:before {
      position: absolute;
      content: "";
      left: 12px;
      top: calc(50% - 7px);
      background: url(/static/media/search.e80071e9.svg);
      height: 14px;
      width: 14px;
      z-index: 1; }
  .stock_management_page .block_search .autocomplete {
    display: flex;
    flex-flow: column;
    position: absolute;
    left: 0;
    top: 51px;
    box-shadow: 0 4px 8px 0 rgba(154, 166, 178, 0.25);
    border-radius: 4px;
    max-height: 300px;
    padding: 12px 0;
    box-sizing: border-box;
    width: 254px;
    overflow: auto;
    background: #fff;
    z-index: 1; }
    .stock_management_page .block_search .autocomplete::-webkit-scrollbar {
      width: 5px;
      height: 5px; }
    .stock_management_page .block_search .autocomplete::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: #ffffff; }
    .stock_management_page .block_search .autocomplete::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #204569; }
    .stock_management_page .block_search .autocomplete::-webkit-scrollbar-thumb:window-inactive {
      background: #204569; }
    .stock_management_page .block_search .autocomplete > button {
      color: #204569;
      text-align: inherit;
      background: transparent;
      font-size: 14px;
      line-height: 20px;
      padding: 4px 24px;
      width: 100%;
      display: flex;
      font-family: "MontRegular", sans-serif; }
      .stock_management_page .block_search .autocomplete > button:focus {
        outline: none; }
    .stock_management_page .block_search .autocomplete .new {
      align-items: center;
      font-family: "MontSemiBold", sans-serif; }
      .stock_management_page .block_search .autocomplete .new span {
        color: #3796f6;
        font-family: "MontSemiBold", sans-serif;
        font-size: 10px;
        text-transform: uppercase;
        line-height: 16px;
        padding-left: 12px; }
    .stock_management_page .block_search .autocomplete .search_item:hover {
      background-color: #d2d2d2;
      border: 2px; }
  .stock_management_page .in_stock_table .table_header .table_row {
    height: 50px !important; }
  .stock_management_page .in_stock_table .table_body .table_row:last-of-type {
    border-bottom: 1px solid rgba(32, 69, 105, 0.25); }
  .stock_management_page .in_stock_table .transactions_columns .table_row {
    height: auto;
    flex-flow: column; }
    .stock_management_page .in_stock_table .transactions_columns .table_row .row {
      width: 100%;
      display: flex;
      height: 60px;
      align-items: center; }
      .stock_management_page .in_stock_table .transactions_columns .table_row .row > :first-child {
        width: 37% !important; }
        .stock_management_page .in_stock_table .transactions_columns .table_row .row > :first-child div {
          white-space: nowrap;
          overflow: hidden;
          padding-right: 15px;
          text-overflow: ellipsis; }
      .stock_management_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) {
        width: 15%;
        display: flex; }
      .stock_management_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) {
        width: 18%;
        display: flex;
        align-items: center; }
        .stock_management_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) button {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer; }
        .stock_management_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) div {
          height: 24px;
          width: 36px;
          border-radius: 2px;
          background-color: #ebf4fe;
          display: flex;
          justify-content: center;
          align-items: center; }
      .stock_management_page .in_stock_table .transactions_columns .table_row .row > :nth-child(4) {
        width: 20%; }
      .stock_management_page .in_stock_table .transactions_columns .table_row .row > :nth-child(5) {
        width: 5%; }
    .stock_management_page .in_stock_table .transactions_columns .table_row .info {
      transition: all 0.2s linear;
      height: 0;
      display: flex;
      width: 100%;
      align-items: center;
      border-radius: 4px;
      background-color: #ebf4fe;
      margin: 0;
      overflow: hidden; }
      .stock_management_page .in_stock_table .transactions_columns .table_row .info .row_item > span {
        opacity: 0.5;
        color: #204569;
        font-family: "MontLight", sans-serif;
        font-size: 10px;
        line-height: 14px; }
      .stock_management_page .in_stock_table .transactions_columns .table_row .info > :first-child {
        width: 36%;
        display: flex;
        align-items: flex-end; }
        .stock_management_page .in_stock_table .transactions_columns .table_row .info > :first-child span {
          display: flex;
          align-items: flex-end;
          margin-right: 12px;
          padding-left: 40px; }
        .stock_management_page .in_stock_table .transactions_columns .table_row .info > :first-child a {
          margin-bottom: -2px;
          color: #3796f6;
          font-family: "MontSemiBold", sans-serif;
          font-size: 14px;
          line-height: 18px; }
      .stock_management_page .in_stock_table .transactions_columns .table_row .info > :nth-child(2) {
        width: 16%;
        display: flex;
        align-items: center; }
        .stock_management_page .in_stock_table .transactions_columns .table_row .info > :nth-child(2) span {
          display: flex;
          align-items: center;
          margin-right: 12px; }
      .stock_management_page .in_stock_table .transactions_columns .table_row .info > :nth-child(3) {
        width: 16%;
        display: flex; }
        .stock_management_page .in_stock_table .transactions_columns .table_row .info > :nth-child(3) span {
          display: flex;
          align-items: center;
          margin-right: 12px; }
      .stock_management_page .in_stock_table .transactions_columns .table_row .info > :nth-child(4) {
        width: 16%;
        display: flex; }
        .stock_management_page .in_stock_table .transactions_columns .table_row .info > :nth-child(4) span {
          display: flex;
          align-items: center;
          margin-right: 12px; }
      .stock_management_page .in_stock_table .transactions_columns .table_row .info > :nth-child(5) {
        width: 16%;
        display: flex; }
        .stock_management_page .in_stock_table .transactions_columns .table_row .info > :nth-child(5) span {
          display: flex;
          align-items: center;
          margin-right: 12px; }
      .stock_management_page .in_stock_table .transactions_columns .table_row .info > :last-child {
        width: 16%;
        display: flex; }
        .stock_management_page .in_stock_table .transactions_columns .table_row .info > :last-child span {
          display: flex;
          align-items: center;
          margin-right: 12px; }
    .stock_management_page .in_stock_table .transactions_columns .table_row .info_open {
      margin-bottom: 20px;
      height: 66px; }
  .stock_management_page .out_of_stock_table .table_body .table_row:last-of-type {
    border-bottom: 1px solid rgba(32, 69, 105, 0.25); }
  .stock_management_page .out_of_stock_table .transactions_columns .table_row > :first-child {
    width: 18%; }
  .stock_management_page .out_of_stock_table .transactions_columns .table_row > :nth-child(2) {
    width: 30%; }
    .stock_management_page .out_of_stock_table .transactions_columns .table_row > :nth-child(2) a {
      color: #3796f6;
      font-family: "MontSemiBold", sans-serif;
      font-size: 14px;
      line-height: 18px; }
  .stock_management_page .out_of_stock_table .transactions_columns .table_row > :nth-child(3) {
    width: 8%; }
  .stock_management_page .out_of_stock_table .transactions_columns .table_row > :nth-child(4) {
    width: 18%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .stock_management_page .out_of_stock_table .transactions_columns .table_row > :nth-child(4) button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
    .stock_management_page .out_of_stock_table .transactions_columns .table_row > :nth-child(4) div {
      height: 24px;
      width: 36px;
      border-radius: 2px;
      background-color: #ebf4fe;
      display: flex;
      justify-content: center;
      align-items: center; }
  .stock_management_page .out_of_stock_table .transactions_columns .table_row > :nth-child(5) {
    width: 13%; }
  .stock_management_page .out_of_stock_table .transactions_columns .table_row > :last-child {
    width: 13%; }
    .stock_management_page .out_of_stock_table .transactions_columns .table_row > :last-child .btn_text {
      text-transform: uppercase;
      font-size: 10px; }
  .stock_management_page .btn_arrow_open img {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .stock_management_page .btn_sort {
    color: rgba(32, 69, 105, 0.5);
    font-size: 10px;
    font-family: "MontLight", sans-serif; }
    .stock_management_page .btn_sort .sort {
      width: auto !important;
      background-color: transparent !important;
      display: flex;
      flex-flow: column;
      margin-left: 3px;
      opacity: 0.5; }
      .stock_management_page .btn_sort .sort img {
        margin: 0.5px 0; }
  .stock_management_page .stub {
    cursor: default !important; }
  .stock_management_page .hided {
    font-size: 10px;
    color: grey !important;
    font-family: "MontSemiBold", sans-serif;
    cursor: default; }

.settings-dialog-wrapper {
  display: flex;
  align-items: center; }

.settings-row {
  display: flex;
  flex-direction: column; }
  .settings-row > span {
    color: #204569;
    font-family: "MontRegular";
    font-size: 14px; }
    .settings-row > span:first-of-type {
      margin-bottom: 42px; }
  .settings-row > input {
    border: 1px solid rgba(32, 69, 105, 0.3);
    border-radius: 4px;
    padding: 14px 16px;
    width: 88px;
    color: #204569;
    font-size: 14px;
    font-family: "MontRegular";
    margin-left: 38px; }
    .settings-row > input:first-of-type {
      margin-bottom: 16px; }

.tab_block {
  margin-bottom: 32px; }
  .tab_block button {
    color: rgba(32, 69, 105, 0.3);
    font-family: "MontSemiBold", sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-right: 32px;
    position: relative; }
    .tab_block button:hover {
      color: #204569; }
    .tab_block button span {
      position: absolute;
      border-radius: 50%;
      top: 1px;
      right: -7px;
      height: 6px;
      width: 6px;
      background-color: #3796f6; }
  .tab_block .active {
    color: #204569; }
  .tab_block .blue-btn {
    color: #3796f6;
    font-family: "MontSemiBold";
    font-size: 10px; }
    .tab_block .blue-btn:hover {
      color: #3796f6; }

.quantity_dialog {
  display: flex;
  flex-flow: column; }
  .quantity_dialog .title {
    padding-bottom: 32px;
    font-family: "MontSemiBold", sans-serif;
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 42px; }
  .quantity_dialog .descriptions {
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 24px; }
    .quantity_dialog .descriptions .name {
      font-family: "MontSemiBold", sans-serif; }
  .quantity_dialog .block_field {
    display: flex;
    flex-flow: column; }
    .quantity_dialog .block_field span {
      font-family: "MontSemiBold", sans-serif;
      font-size: 10px;
      line-height: 13px;
      padding-bottom: 8px; }
    .quantity_dialog .block_field .error {
      bottom: -25px !important; }
    .quantity_dialog .block_field input {
      padding: 0 16px;
      box-sizing: border-box;
      border: 1px solid rgba(146, 148, 151, 0.3);
      border-radius: 4px;
      height: 48px;
      width: 124px;
      font-family: "MontRegular", sans-serif;
      font-size: 14px; }
      .quantity_dialog .block_field input::-webkit-input-placeholder {
        color: rgba(32, 69, 105, 0.5); }
      .quantity_dialog .block_field input::placeholder {
        color: rgba(32, 69, 105, 0.5); }
  .quantity_dialog .btn_wrapper {
    display: flex;
    padding-top: 2px; }
    .quantity_dialog .btn_wrapper .cancel_btn {
      margin-right: 16px; }
    .quantity_dialog .btn_wrapper button {
      margin-right: 16px; }

#stocklink {
  color: #3796f6; }

#stocklink:hover {
  text-decoration: underline; }

.user_management_page {
  display: flex;
  flex-flow: column; }
  .user_management_page .content_page {
    background: #ffffff;
    padding: 36px 40px;
    margin-bottom: 72px;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
    border-radius: 10px; }
    .user_management_page .content_page.limits > div {
      display: inline-block; }
      .user_management_page .content_page.limits > div .change_limit_wrapper {
        display: flex;
        margin-left: 15px; }
        .user_management_page .content_page.limits > div .change_limit_wrapper input,
        .user_management_page .content_page.limits > div .change_limit_wrapper button {
          text-align: center;
          line-height: 24px; }
        .user_management_page .content_page.limits > div .change_limit_wrapper input {
          height: 24px;
          width: 36px;
          border-radius: 2px;
          background-color: #ebf4fe; }
        .user_management_page .content_page.limits > div .change_limit_wrapper button {
          height: 24px;
          width: 24px; }
    .user_management_page .content_page.limits > span {
      display: inline-block; }
    .user_management_page .content_page.limits > button {
      margin-top: 50px; }
      .user_management_page .content_page.limits > button + .error {
        color: red;
        font-size: 12px;
        margin-top: 10px; }
  .user_management_page .table_panel {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .user_management_page .table_panel button {
      margin-right: 24px;
      color: #3796f6;
      text-transform: uppercase;
      font-size: 10px;
      font-family: "MontSemiBold", sans-serif; }
  .user_management_page .user_management_table .transactions_columns .table_row > :first-child {
    width: 30%; }
  .user_management_page .user_management_table .transactions_columns .table_row > :nth-child(2) {
    width: 38%; }
  .user_management_page .user_management_table .transactions_columns .table_row > :nth-child(3) {
    width: 22%; }
  .user_management_page .user_management_table .transactions_columns .table_row > :last-child {
    width: 10%; }
    .user_management_page .user_management_table .transactions_columns .table_row > :last-child button {
      margin-right: 24px;
      text-transform: uppercase;
      font-family: "MontSemiBold", sans-serif;
      font-size: 10px; }
      .user_management_page .user_management_table .transactions_columns .table_row > :last-child button:hover {
        opacity: 0.7; }
  .user_management_page .user_management_table .transactions_columns .cap {
    color: #9aa6b2;
    font-size: 14px;
    font-family: "MontSemiBold", sans-serif; }

.inputFields {
  margin-top: 40px; }

.stepper-input {
  display: flex;
  font-size: 15px;
  padding-bottom: 15px; }

.addButton {
  cursor: pointer;
  padding: 5px;
  color: #fff;
  background-color: #3796f6;
  border: 1px solid #3796f6; }

.addButton:hover {
  background-color: #fff;
  color: #3796f6; }

.btn-left {
  border-radius: 10px 0 0 10px; }

.btn-right {
  border-radius: 0 10px 10px 0; }

.stocklink {
  color: #3796f6; }

.stocklink:hover {
  text-decoration: underline; }

.input-box {
  border: 1px solid #3796f6;
  text-align: center;
  width: 90px; }

.suggested1 {
  width: 80% !important; }

.suggested2 {
  width: 10% !important;
  background-color: #fff !important; }

.suggested3 {
  width: 10% !important; }

.table_row1 {
  height: auto;
  flex-flow: column;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 58px;
  border-top: 1px solid rgba(32, 69, 105, 0.25);
  text-decoration: none; }
  .table_row1 .row {
    width: 100%;
    display: flex;
    height: 60px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .table_row1 .light {
    color: #204569 !important;
    font-family: MontRegular, sans-serif !important;
    font-size: 16px !important; }

.table_header .table_row1 {
  border: none; }

.cart_title {
  color: #204569;
  font-family: MontRegular, sans-serif;
  font-size: 16px; }

.title_block {
  border: none; }

hr {
  border: none; }

.activity_page {
  display: flex;
  flex-flow: column; }
  .activity_page h3 {
    padding: 20px 0; }
  .activity_page .pagination_info_wrapper {
    padding-bottom: 20px;
    border-top: 1px solid rgba(32, 69, 105, 0.25); }
  .activity_page .activity_block {
    padding-bottom: 10px; }

.in_stock_table .title_block {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .in_stock_table .title_block .cart_buttons {
    margin: 0; }

.in_stock_table .cart_container .cart_items {
  margin: 0; }

.cart_items .cart_list {
  border: none;
  box-shadow: none;
  padding-left: 0; }
  .cart_items .cart_list .cart_item {
    cursor: pointer;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(32, 69, 105, 0.25);
    padding: 15px 0px;
    padding-bottom: 32px;
    list-style: none !important; }

.cart_items .cart_item_image {
  width: 80px;
  height: 80px;
  float: left; }
  .cart_items .cart_item_image img {
    width: 80px;
    height: 80px;
    border-radius: 4px; }

.cart_item_text {
  margin-top: 6px;
  color: #204569;
  font-size: 16px;
  font-family: "MontSemiBold"; }
  .cart_item_text .row {
    display: flex;
    align-items: center;
    margin: 0; }
    .cart_item_text .row > input {
      border: 1px solid #dee4e9;
      border-radius: 4px;
      font-size: 14px;
      font-family: "MontRegular";
      color: #204569;
      width: 42px;
      height: 28px;
      text-align: center; }
    .cart_item_text .row > button {
      color: #204569; }
      .cart_item_text .row > button:first-of-type {
        margin-right: 8px; }
      .cart_item_text .row > button:last-of-type {
        margin-left: 8px; }

.cart_buttons {
  text-align: left; }
  .cart_buttons > button {
    height: 48px;
    width: 180px;
    border-radius: 4px;
    background-color: #3796f6;
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    font-family: "MontBold";
    padding: 0; }

.suggested-wrapper {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
  padding: 32px 40px; }
  .suggested-wrapper .suggested1 {
    width: 50% !important; }
  .suggested-wrapper .suggested2 {
    width: 25% !important; }

.cart_item_title {
  color: #204569;
  font-size: 10px;
  opacity: 0.5; }

.cart_item_name {
  margin-left: 24px; }

.activity_block {
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
  padding: 32px 40px;
  border-radius: 10px; }
  .activity_block .panel_activity {
    padding-bottom: 12px; }
  .activity_block .activity_info_wrapper > div > div {
    border-bottom: 1px solid rgba(32, 69, 105, 0.25);
    display: flex;
    padding-top: 20px;
    flex-flow: column; }
    .activity_block .activity_info_wrapper > div > div > span {
      opacity: 0.5;
      font-family: "MontLight", sans-serif;
      font-size: 10px;
      line-height: 13px;
      padding-bottom: 8px;
      display: flex; }
    .activity_block .activity_info_wrapper > div > div p {
      padding-bottom: 20px;
      font-size: 14px; }
      .activity_block .activity_info_wrapper > div > div p span {
        font-family: "MontSemiBold", sans-serif; }
  .activity_block .activity_info_wrapper > div:last-of-type > div {
    border-bottom: none; }

.cart-page {
  min-height: calc(100vh - 240px); }

@charset "UTF-8";
.activity_page {
  display: flex;
  flex-flow: column; }
  .activity_page h3 {
    padding: 20px 0; }
  .activity_page .pagination_info_wrapper {
    padding-bottom: 20px;
    border-top: 1px solid rgba(32, 69, 105, 0.25); }
  .activity_page .activity_block {
    padding-bottom: 10px; }
  .activity_page .in_stock_table .table_header .table_row {
    height: 50px !important; }
  .activity_page .in_stock_table .table_body .table_row:last-of-type {
    border-bottom: 1px solid rgba(32, 69, 105, 0.25); }
  .activity_page .in_stock_table .transactions_columns .table_row {
    height: auto;
    flex-flow: column; }
    .activity_page .in_stock_table .transactions_columns .table_row .row {
      width: 100%;
      display: flex;
      height: 60px;
      align-items: center;
      padding: 0 40px; }
      .activity_page .in_stock_table .transactions_columns .table_row .row.highlighted {
        background-color: rgba(235, 244, 254, 0.5);
        border-top: 1px solid rgba(55, 150, 246, 0.25);
        border-bottom: 1px solid rgba(55, 150, 246, 0.25);
        position: relative;
        -webkit-animation: pulse 1.5s infinite;
                animation: pulse 1.5s infinite; }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(55, 150, 246, 0.25); }
  70% {
    box-shadow: 0 0 0 8px rgba(204, 169, 44, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(55, 150, 246, 0.25); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }
      .activity_page .in_stock_table .transactions_columns .table_row .row > :first-child {
        width: 30% !important; }
        @media only screen and (max-width: 1200px) {
          .activity_page .in_stock_table .transactions_columns .table_row .row > :first-child {
            width: 25% !important; } }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :first-child div {
          white-space: nowrap;
          overflow: hidden;
          padding-right: 15px;
          text-overflow: ellipsis; }
      .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) {
        width: 40%;
        display: flex;
        align-items: center; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) > span {
          text-transform: capitalize;
          color: #204569;
          font-family: "MontSemiBold";
          font-size: 14px;
          margin-right: 40px;
          display: flex;
          flex-direction: column; }
          .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) > span.row-alighned {
            flex-direction: row;
            align-items: center; }
            .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) > span.row-alighned .refunded_status_label {
              margin-left: 16px;
              padding: 8px;
              border-radius: 14px;
              background-color: rgba(143, 162, 180, 0.1); }
          @media only screen and (max-width: 992px) {
            .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) > span {
              font-size: 12px; } }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) > button {
          text-transform: uppercase;
          color: #3796f6;
          font-family: "MontSemiBold";
          font-size: 10px; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) .download {
          margin-left: 20px;
          position: relative; }
          .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) .download::before {
            content: "\25CF";
            color: #3796f6;
            position: absolute;
            left: -12px;
            top: 0px;
            font-size: 8px; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) > div {
          display: flex;
          align-items: start;
          justify-content: center; }
      .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) {
        width: 30%;
        display: flex;
        align-items: center;
        position: relative; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3).with-padding {
          padding-left: 182px; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) button {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) div {
          height: 24px;
          width: 36px;
          border-radius: 2px;
          background-color: #ebf4fe;
          display: flex;
          justify-content: center;
          align-items: center; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) .confirm-btn {
          max-width: 89px;
          width: 100%;
          height: 32px;
          border-radius: 4px;
          background-color: #e9f8f4;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #2cc197;
          font-size: 10px;
          font-family: "MontBold";
          text-transform: uppercase; }
          .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) .confirm-btn.alone {
            width: 84px; }
          .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) .confirm-btn > img {
            margin-right: 4px; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) .reject-btn {
          max-width: 89px;
          width: 100%;
          height: 32px;
          border-radius: 4px;
          background-color: #fcecec;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #e44b4b;
          font-size: 10px;
          font-family: "MontBold";
          text-transform: uppercase;
          align-self: center; }
          .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) .reject-btn > img {
            margin-right: 4px; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) .download-btn {
          width: 89px;
          height: 32px;
          border-radius: 4px;
          font-size: 10px;
          font-family: "MontBold";
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ebf4fe;
          color: #3796f6; }
          .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) .download-btn > img {
            margin-right: 4px; }
    .activity_page .in_stock_table .transactions_columns .table_row .info {
      transition: all 0.2s linear;
      height: 0;
      display: flex;
      width: 100%;
      align-items: center;
      border-radius: 4px;
      background-color: #ebf4fe;
      margin: 0;
      overflow: hidden; }
      .activity_page .in_stock_table .transactions_columns .table_row .info .row_item > span {
        opacity: 0.5;
        color: #204569;
        font-family: "MontLight", sans-serif;
        font-size: 10px;
        line-height: 14px; }
      .activity_page .in_stock_table .transactions_columns .table_row .info > :first-child {
        width: 36%;
        display: flex;
        align-items: flex-end; }
        .activity_page .in_stock_table .transactions_columns .table_row .info > :first-child span {
          display: flex;
          align-items: flex-end;
          margin-right: 12px;
          padding-left: 40px; }
        .activity_page .in_stock_table .transactions_columns .table_row .info > :first-child a {
          margin-bottom: -2px;
          color: #3796f6;
          font-family: "MontSemiBold", sans-serif;
          font-size: 14px;
          line-height: 18px; }
          @media only screen and (max-width: 992px) {
            .activity_page .in_stock_table .transactions_columns .table_row .info > :first-child a {
              font-size: 12px; } }
      .activity_page .in_stock_table .transactions_columns .table_row .info > :nth-child(2) {
        width: 16%;
        display: flex;
        align-items: center; }
        .activity_page .in_stock_table .transactions_columns .table_row .info > :nth-child(2) span {
          display: flex;
          align-items: center;
          margin-right: 12px; }
      .activity_page .in_stock_table .transactions_columns .table_row .info > :nth-child(3) {
        width: 16%;
        display: flex; }
        .activity_page .in_stock_table .transactions_columns .table_row .info > :nth-child(3) span {
          display: flex;
          align-items: center;
          margin-right: 12px; }
    .activity_page .in_stock_table .transactions_columns .table_row .info_open {
      margin-bottom: 20px;
      height: 66px; }

.activity_block {
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
  padding: 32px 00px;
  border-radius: 10px; }
  .activity_block .waiting :disabled {
    cursor: not-allowed; }
  .activity_block .waiting.notification_for_file {
    position: relative; }
    .activity_block .waiting.notification_for_file:before {
      content: attr(notification) "";
      display: none;
      position: absolute;
      bottom: calc(100% + 5px);
      left: 80%;
      background: rgba(51, 51, 51, 0.9);
      width: 100px;
      text-align: left;
      padding: 5px 10px;
      border-radius: 5px 5px 5px 0; }
    .activity_block .waiting.notification_for_file:hover:before {
      display: block; }
    .activity_block .waiting.notification_for_file.no_tooltip:before {
      display: none; }
  .activity_block .waiting .MuiCircularProgress-root {
    height: 12px !important;
    width: 12px !important;
    margin-right: 5px; }
    .activity_block .waiting .MuiCircularProgress-root circle {
      stroke-width: 5; }
  .activity_block .select_wrapper {
    margin-bottom: 30px;
    margin-left: 40px; }
  .activity_block .panel_activity {
    padding-bottom: 12px; }
  .activity_block .activity_info_wrapper > div > div {
    border-bottom: 1px solid rgba(32, 69, 105, 0.25);
    display: flex;
    padding-top: 20px;
    flex-flow: column; }
    .activity_block .activity_info_wrapper > div > div > span {
      opacity: 0.5;
      font-family: "MontLight", sans-serif;
      font-size: 10px;
      line-height: 13px;
      padding-bottom: 8px;
      display: flex; }
    .activity_block .activity_info_wrapper > div > div p {
      padding-bottom: 20px;
      font-size: 14px; }
      @media only screen and (max-width: 992px) {
        .activity_block .activity_info_wrapper > div > div p {
          font-size: 12px; } }
      .activity_block .activity_info_wrapper > div > div p span {
        font-family: "MontSemiBold", sans-serif; }
  .activity_block .activity_info_wrapper > div:last-of-type > div {
    border-bottom: none; }

#proformaTitle {
  text-align: center;
  vertical-align: middle;
  font-style: bold;
  font-weight: bold; }

#clientDesc {
  font-style: bold;
  font-weight: bold; }

.table-bordered {
  border: 1px solid black; }
  .table-bordered .delete_item_btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    background-color: rgba(255, 0, 0, 0.1);
    transition: background-color 250ms; }
    .table-bordered .delete_item_btn::after, .table-bordered .delete_item_btn::before {
      content: "";
      display: block;
      width: 20px;
      height: 4px;
      border-radius: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: #ff7272;
      transition: background-color 250ms; }
    .table-bordered .delete_item_btn::before {
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
              transform: translate(-50%, -50%) rotate(45deg); }
    .table-bordered .delete_item_btn::after {
      -webkit-transform: translate(-50%, -50%) rotate(-45deg);
              transform: translate(-50%, -50%) rotate(-45deg); }
    .table-bordered .delete_item_btn:hover {
      background-color: rgba(255, 0, 0, 0.5); }
      .table-bordered .delete_item_btn:hover::after, .table-bordered .delete_item_btn:hover::before {
        background-color: red; }

.modelBody .table-bordered tr td {
  border: 1px solid black; }

thead tr th,
.btn-sm {
  font-style: bold;
  font-weight: bold; }

#my-table {
  background-color: #ebf4fe; }
  #my-table tr {
    background-color: #ebf4fe; }
    #my-table tr td {
      background-color: #ebf4fe; }
      #my-table tr td .lol {
        background-color: #ebf4fe; }

p.without-margin,
.download-txt-block {
  margin-bottom: 0 !important; }

.download-img-block {
  margin-right: 0 !important; }

.download-txt-block {
  color: #204569;
  font-size: 10px;
  text-transform: uppercase;
  font-family: "MontSemiBold", sans-serif;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  margin-left: 4px;
  align-self: center;
  padding-top: 5px;
  cursor: pointer; }
  .download-txt-block.with-margin {
    margin-right: 92px; }
    .download-txt-block.with-margin.arrow {
      margin-right: 80px; }
  .download-txt-block:hover {
    text-decoration: none;
    color: #204569; }

.activity_dialog {
  padding: 48px 64px; }
  .activity_dialog-title span {
    color: #204569;
    font-size: 32px;
    line-height: 42px;
    font-family: "MontSemiBold", sans-serif; }
  .activity_dialog-txt {
    color: #204569;
    margin-top: 32px;
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 22px;
    font-family: "MontSemiBold", sans-serif; }
    @media only screen and (max-width: 992px) {
      .activity_dialog-txt {
        font-size: 12px; } }
  .activity_dialog .btn_wrapper {
    margin-top: 32px;
    padding-top: 32px;
    display: flex;
    border-top: 1px solid rgba(32, 69, 105, 0.5); }
    .activity_dialog .btn_wrapper button:first-child {
      margin-right: 16px; }

.block_field.stamp-block .stamp-title {
  font-size: 10px; }

.block_field.stamp-block .activity_dialog-txt {
  margin-bottom: 24px !important;
  white-space: break-spaces; }

.block_field.stamp-block .blue_btn.upload {
  height: 48px;
  width: 116px;
  text-transform: none;
  font-size: 14px;
  font-family: "MontSemiBold", sans-serif;
  cursor: pointer; }
  @media only screen and (max-width: 992px) {
    .block_field.stamp-block .blue_btn.upload {
      font-size: 12px; } }

.stamp-block label {
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px; }
  @media only screen and (max-width: 992px) {
    .stamp-block label {
      font-size: 12px; } }

.stamp-block-row {
  display: flex; }

.stamp-block-btn {
  background-color: transparent !important;
  width: 15px !important;
  margin-top: 0 !important;
  margin-left: 6px !important; }

.stamp-block-title {
  text-overflow: ellipsis;
  padding-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  color: #3796f6 !important;
  opacity: 1 !important;
  font-family: "MontSemiBold", sans-serif;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  max-width: 186px;
  display: flex;
  align-self: center;
  margin-bottom: 0 !important;
  margin-left: 6px !important;
  font-size: 14px; }
  @media only screen and (max-width: 992px) {
    .stamp-block-title {
      font-size: 12px; } }

.download-arrow {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-right: 80px;
  margin-left: 5px;
  transition: all 0.3s;
  cursor: pointer; }
  .download-arrow.open {
    transition: all 0.3s;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }

.download_block {
  margin-right: 25px; }

.dropdown-list {
  display: none !important;
  flex-direction: column;
  position: absolute;
  max-width: 147px;
  width: 100%;
  padding: 16px;
  background: #fff;
  box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
  border-radius: 4px;
  z-index: 1000;
  top: 30px; }
  .dropdown-list a {
    color: #204569;
    text-decoration: none;
    font-family: "MontSemiBold", sans-serif;
    border: none; }
    .dropdown-list a:hover {
      background: none;
      color: rgba(33, 69, 105, 0.7); }
  .dropdown-list.open {
    display: flex !important; }

.row_item span.rejected_status_label {
  color: #e44b4b;
  line-height: 13px !important;
  font-size: 10px !important;
  font-family: "MontSemiBold", sans-serif !important; }

.row_item span.refunded_status_label {
  color: #8fa2b4 !important;
  line-height: 13px !important;
  font-size: 10px !important;
  font-family: "MontSemiBold", sans-serif !important; }

.refunded_status_label {
  color: #8fa2b4 !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important; }
  .refunded_status_label img {
    margin-right: 5px;
    height: 12px !important;
    width: 12px !important; }

.activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) div {
  max-width: 200px !important;
  width: 100% !important;
  background-color: #fff;
  padding: 12px 16px !important;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.01);
  line-height: 20px;
  font-family: "MontSemiBold", sans-serif !important;
  font-size: 14px;
  color: #204569; }
  @media only screen and (max-width: 992px) {
    .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) div {
      font-size: 12px; } }
  .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) div::before {
    display: none; }
  .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) div::after {
    display: none; }

.table_row-elem {
  display: none; }

@media only screen and (max-width: 1200px) {
  .activity_page .row_item.with-padding {
    padding-left: 110px !important; }
  .activity_page .download-arrow {
    margin-right: 10px; }
  .activity_page .table_row .row .row_item.row_item-date {
    width: 25% !important;
    font-size: 10px !important; }
  .row_item-date {
    font-size: 10px !important; } }

@media only screen and (max-width: 992px) {
  .row_item.with-padding {
    padding-left: 0 !important; }
  .download-arrow {
    margin-right: 10px; } }

@media only screen and (max-width: 900px) {
  .table_header,
  .table_row {
    display: none !important; }
  .table_row-elem {
    display: flex; }
  .search-wrapper,
  .cart-wrapper {
    display: none; }
  .drop-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    width: 208px;
    border-radius: 4px;
    z-index: 100;
    padding: 16px 24px;
    top: 50px;
    right: 0px; }
    .drop-menu-btn {
      display: flex;
      align-items: center;
      margin-bottom: 10px; }
      .drop-menu-btn.border {
        border: none !important;
        border-bottom: 1.2px solid rgba(32, 69, 105, 0.25) !important;
        width: 100%;
        padding-bottom: 12px; }
      .drop-menu-btn img {
        margin-right: 8px; }
      .drop-menu-btn span {
        color: #204569;
        font-size: 12px;
        font-family: "MontRegular";
        text-decoration: none; }
    .drop-menu > div {
      font-family: "MontRegular";
      color: #8fa2b4;
      font-size: 12px;
      cursor: pointer;
      border-top: 1px solid rgba(32, 69, 105, 0.15);
      padding-top: 16px; }
  .table_row-elem {
    padding: 24px 0;
    border-bottom: 1px solid rgba(32, 69, 105, 0.25) !important;
    display: flex;
    flex-direction: column; }
    .table_row-elem:first-child {
      border-top: 1px solid rgba(32, 69, 105, 0.25) !important; }
    .table_row-elem.highlighted {
      background-color: rgba(235, 244, 254, 0.5);
      border-top: 1px solid rgba(55, 150, 246, 0.25);
      border-bottom: 1px solid rgba(55, 150, 246, 0.25);
      position: relative;
      -webkit-animation: pulse 1.5s infinite;
              animation: pulse 1.5s infinite; }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(55, 150, 246, 0.25); }
    70% {
      box-shadow: 0 0 0 8px rgba(204, 169, 44, 0); }
    100% {
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(55, 150, 246, 0.25); }
    70% {
      -webkit-box-shadow: 0 0 0 8px rgba(204, 169, 44, 0); }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }
  .table_row-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 40px; }
  .table_row-status {
    display: flex;
    padding: 0 40px; }
    .table_row-status span {
      text-transform: capitalize;
      color: #204569;
      font-family: "MontSemiBold";
      font-size: 14px;
      margin-right: 40px;
      display: flex;
      flex-direction: row; }
      .table_row-status span span {
        margin-left: 8px;
        color: #e44b4b;
        line-height: 13px !important;
        font-size: 10px !important;
        font-family: "MontSemiBold";
        display: flex;
        flex-direction: column;
        justify-content: center; }
  .table_row-buttons {
    display: flex;
    margin-top: 20px;
    padding: 0 40px; }
    .table_row-buttons .confirm-btn {
      width: 120px;
      height: 32px;
      border-radius: 4px;
      background-color: #e9f8f4;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #2cc197;
      font-size: 10px;
      font-family: "MontBold";
      text-transform: uppercase; }
      .table_row-buttons .confirm-btn > img {
        margin-right: 4px; }
    .table_row-buttons .reject-btn {
      width: 120px;
      height: 32px;
      border-radius: 4px;
      background-color: #fcecec;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #e44b4b;
      font-size: 10px;
      font-family: "MontBold";
      text-transform: uppercase;
      align-self: center; }
      .table_row-buttons .reject-btn > img {
        margin-right: 4px; } }

@media only screen and (max-width: 700px) {
  .content_block {
    padding: 48px 15px 89px; }
  .header_wrapper,
  footer {
    padding: 15px !important; }
    .header_wrapper .down-wrapper,
    footer .down-wrapper {
      display: flex;
      justify-content: center; }
  footer .upper-wrapper {
    flex-direction: column !important; } }

@media only screen and (max-width: 576px) {
  footer .upper-wrapper {
    flex-direction: column !important; }
    footer .upper-wrapper > div {
      margin-top: 15px !important; }
      footer .upper-wrapper > div a {
        font-size: 10px !important; }
  .table_row-buttons .confirm-btn.fullwidth {
    width: 100% !important; }
  .table_row-buttons .confirm-btn {
    width: 50% !important; }
  .table_row-buttons .reject-btn {
    width: 50% !important; }
  .activity_block {
    padding: 16px; }
  .activity_dialog {
    padding: 48px 24px; }
  .activity_dialog-title span {
    font-size: 24px; }
  .activity_dialog-txt {
    margin-top: 16px;
    white-space: break-spaces; }
  .modelBody.modal-body tbody tr:first-child {
    display: flex;
    flex-direction: column; }
    .modelBody.modal-body tbody tr:first-child img {
      margin-bottom: 20px; }
    .modelBody.modal-body tbody tr:first-child td {
      font-size: 12px; }
  .modal-content {
    border: none; }
  .modal-header,
  .modal-footer {
    width: 476px; }
  .table_row-elem.highlighted:before {
    top: -1px;
    width: 16px;
    left: -16px; }
  .table_row-elem.highlighted:after {
    top: -1px;
    width: 16px;
    right: -16px; }
  .activity_block .select_wrapper {
    margin-left: 00px; }
  .table_row-header,
  .table_row-status,
  .table_row-buttons {
    padding: 0 5px; } }

footer {
  width: 100%;
  background-color: #364e65;
  padding: 32px 64px; }
  footer .upper-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
    footer .upper-wrapper > div {
      transition: all 1.5s;
      display: flex;
      align-items: center; }
      footer .upper-wrapper > div > a {
        text-decoration: none;
        font-size: 14px;
        color: #ffffff;
        font-family: "MontSemiBold";
        margin-left: 20px; }
    footer .upper-wrapper .active {
      opacity: 0.5; }
    footer .upper-wrapper .singin,
    footer .upper-wrapper .singup {
      text-transform: uppercase;
      transition: all 1.5s; }
    footer .upper-wrapper .singin {
      height: 40px;
      width: 98px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center; }
      footer .upper-wrapper .singin:hover {
        border: 1px solid #ffffff; }
    footer .upper-wrapper .singup {
      border-radius: 4px;
      height: 40px;
      width: 98px;
      display: flex;
      align-items: center;
      justify-content: center; }
      footer .upper-wrapper .singup:hover {
        border: 1px solid #ffffff; }
  footer .down-wrapper {
    padding-top: 32px;
    opacity: 0.5;
    color: #ffffff;
    font-family: "MontLight";
    font-size: 10px; }

.category-wrapper {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 240px); }
  .category-wrapper .content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; }
    .category-wrapper .content-wrapper .results-wrapper {
      background-color: #ebf4fe;
      width: 100%;
      padding: 32px 32px 64px 32px; }
      .category-wrapper .content-wrapper .results-wrapper .breadcrams {
        display: flex;
        align-items: center;
        margin-bottom: 12px; }
        .category-wrapper .content-wrapper .results-wrapper .breadcrams .breadcram-single {
          margin-right: 8px;
          display: flex;
          align-items: center;
          color: #3796f6;
          font-size: 10px;
          text-transform: uppercase;
          font-family: "MontSemiBold"; }
          .category-wrapper .content-wrapper .results-wrapper .breadcrams .breadcram-single > img {
            margin-right: 4px;
            display: block; }
          .category-wrapper .content-wrapper .results-wrapper .breadcrams .breadcram-single > a {
            display: block;
            font-size: 10px;
            line-height: 10px; }
      .category-wrapper .content-wrapper .results-wrapper > h1 {
        font-size: 32px;
        color: #204569;
        font-family: "MontSemiBold";
        margin-bottom: 32px; }
      .category-wrapper .content-wrapper .results-wrapper .no-results {
        font-size: 24px;
        color: #204569;
        font-family: "MontSemiBold"; }
      .category-wrapper .content-wrapper .results-wrapper .results {
        display: flex;
        flex-wrap: wrap; }
    .category-wrapper .content-wrapper .search-pagination {
      padding: 32px 0;
      display: flex;
      align-items: center;
      justify-content: center; }

.filter-menu {
  width: 368px;
  border: 2px solid rgba(55, 150, 246, 0.1);
  background-color: #ffffff;
  padding: 32px 16px 32px 64px; }
  .filter-menu .categories-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px; }
    .filter-menu .categories-wrapper .view-more {
      font-family: "MontSemiBold";
      color: #3796f6;
      font-size: 10px;
      cursor: pointer; }
    .filter-menu .categories-wrapper > p {
      color: #204569;
      font-size: 16px;
      font-family: "MontSemiBold"; }
    .filter-menu .categories-wrapper .category,
    .filter-menu .categories-wrapper .category-child {
      color: #204569;
      font-family: "MontLight";
      font-size: 12px;
      margin-bottom: 8px;
      cursor: pointer; }
      .filter-menu .categories-wrapper .category:hover,
      .filter-menu .categories-wrapper .category-child:hover {
        font-family: "MontBold"; }
    .filter-menu .categories-wrapper .category-child {
      margin-left: 16px; }
    .filter-menu .categories-wrapper .active {
      font-family: "MontBold"; }
    .filter-menu .categories-wrapper .mt16 {
      margin-top: 8px; }
  .filter-menu .manufacturer-wrapper > p {
    color: #204569;
    font-size: 16px;
    font-family: "MontSemiBold"; }
  .filter-menu .manufacturer-wrapper .manufacturers .single-man {
    display: flex;
    align-items: center; }
    .filter-menu .manufacturer-wrapper .manufacturers .single-man .man-text {
      font-size: 12px;
      color: #204569;
      font-family: "MontLight"; }
    .filter-menu .manufacturer-wrapper .manufacturers .single-man .active {
      font-family: "MontBold"; }

.product-card {
  height: 384px;
  width: 304px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
  padding: 24px;
  margin-bottom: 32px;
  margin-right: 32px;
  margin-bottom: 32px;
  text-decoration: none !important;
  position: relative;
  cursor: pointer;
  transition: all 0.5s; }
  .product-card:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .product-card .name {
    color: #204569;
    font-family: "MontSemiBold";
    font-size: 14px;
    margin-bottom: 8px;
    max-height: 38px;
    overflow: hidden;
    line-height: 18px; }
  .product-card .description {
    font-family: "MontLight";
    color: #204569;
    font-size: 10px;
    max-height: 32px;
    overflow: hidden;
    margin-bottom: 16px; }
  .product-card > img {
    height: 192px;
    width: 256px;
    object-fit: cover;
    margin-bottom: 16px; }
  .product-card .row-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 24px;
    width: 256px;
    justify-content: space-between; }
    .product-card .row-wrapper > span {
      color: #fda45c;
      font-family: "MontBold";
      font-size: 14px;
      margin-right: 10px; }
    .product-card .row-wrapper > div {
      display: flex;
      align-items: center; }
    .product-card .row-wrapper .input-wrapper {
      display: flex;
      align-items: center;
      margin-right: 16px; }
      .product-card .row-wrapper .input-wrapper > input {
        width: 42px;
        height: 28px;
        border: 1px solid #dee4e9;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        font-family: "MontRegular";
        color: #204569; }
      .product-card .row-wrapper .input-wrapper > button {
        font-family: "MontRegular";
        color: #204569;
        font-size: 14px; }
        .product-card .row-wrapper .input-wrapper > button:disabled {
          opacity: 0.3; }
        .product-card .row-wrapper .input-wrapper > button:first-of-type {
          margin-right: 8px; }
        .product-card .row-wrapper .input-wrapper > button:last-of-type {
          margin-left: 8px; }
    .product-card .row-wrapper .add-cart {
      height: 36px;
      width: 62px;
      border-radius: 4px;
      background-color: #ebf4fe;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      color: #3796f6;
      font-family: "MontBold";
      text-transform: uppercase; }
      .product-card .row-wrapper .add-cart > img {
        margin-right: 2px; }

.search-pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent; }
  .search-pagination-container .pagination-list {
    display: flex;
    margin-bottom: 0; }
    .search-pagination-container .pagination-list li {
      border-radius: 2px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all ease 0.3s; }
      .search-pagination-container .pagination-list li:hover {
        background: rgba(55, 150, 246, 0.2); }
      .search-pagination-container .pagination-list li a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 20px; }
  .search-pagination-container .pagination-link {
    cursor: pointer;
    background: transparent;
    color: #3796f6;
    text-decoration: none;
    text-align: center;
    line-height: 28px;
    height: 24px;
    width: 24px;
    font-size: 14px;
    min-width: 24px; }
    .search-pagination-container .pagination-link.pagination-link-active {
      border-radius: 2px;
      background: #3796f6;
      color: #ffffff; }
      .search-pagination-container .pagination-link.pagination-link-active:hover {
        opacity: 0.7; }
  .search-pagination-container .pagination-ellipsis {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none; }
    .search-pagination-container .pagination-ellipsis a {
      cursor: pointer;
      background: transparent;
      height: 24px;
      width: 24px;
      color: #3796f6;
      text-decoration: none;
      text-align: center;
      line-height: 28px; }
  .search-pagination-container .next,
  .search-pagination-container .previous {
    margin: 0; }
    .search-pagination-container .next.disabled:hover,
    .search-pagination-container .previous.disabled:hover {
      background: transparent; }
    .search-pagination-container .next.disabled a,
    .search-pagination-container .previous.disabled a {
      opacity: 0.5;
      cursor: not-allowed; }
    .search-pagination-container .next a,
    .search-pagination-container .previous a {
      display: flex;
      align-items: center;
      justify-content: center; }
      .search-pagination-container .next a svg,
      .search-pagination-container .previous a svg {
        font-size: 16px;
        fill: #3796f6; }

.subscriptions_page {
  display: flex;
  flex-flow: column;
  font-family: "MontRegular",sans-serif; }
  .subscriptions_page h3 {
    padding: 20px 0; }
  .subscriptions_page .subscriptions_block {
    background: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
    padding: 32px 40px;
    border-radius: 10px; }
    .subscriptions_page .subscriptions_block .option {
      margin-bottom: 17px;
      display: flex;
      align-items: center; }
      .subscriptions_page .subscriptions_block .option .block_search .input-group .search_info input {
        width: 280px; }
      .subscriptions_page .subscriptions_block .option .select_wrapper {
        margin-left: 24px;
        width: 200px; }
  .subscriptions_page .pagination-container {
    border-top: 1px solid rgba(32, 69, 105, 0.25);
    padding-top: 32px; }
    .subscriptions_page .pagination-container .pagination-list {
      margin: 0;
      padding: 0; }
      .subscriptions_page .pagination-container .pagination-list li a:hover {
        text-decoration: none; }
      .subscriptions_page .pagination-container .pagination-list .pagination-ellipsis a {
        color: #3796F6; }
  .subscriptions_page .in_stock_table .table_body .table_row .row {
    height: 60px; }
  .subscriptions_page .in_stock_table .table_header .table_row .row {
    height: 45px; }
  .subscriptions_page .in_stock_table .transactions_columns .table_row .row {
    width: 100%;
    display: flex;
    margin: 0;
    align-items: center; }
    .subscriptions_page .in_stock_table .transactions_columns .table_row .row > :first-child {
      width: 10%; }
    .subscriptions_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) {
      width: 10%; }
    .subscriptions_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) {
      width: 30%; }
    .subscriptions_page .in_stock_table .transactions_columns .table_row .row > :nth-child(4) {
      width: 10%; }
    .subscriptions_page .in_stock_table .transactions_columns .table_row .row > :nth-child(5) {
      width: 10%; }
    .subscriptions_page .in_stock_table .transactions_columns .table_row .row > :nth-child(6) {
      width: 10%; }
    .subscriptions_page .in_stock_table .transactions_columns .table_row .row > :nth-child(7) {
      width: 10%; }
    .subscriptions_page .in_stock_table .transactions_columns .table_row .row > :last-of-type {
      width: 10%; }
  .subscriptions_page .in_stock_table .transactions_columns .table_row .status {
    font-family: "MontSemiBold",sans-serif;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize; }
    .subscriptions_page .in_stock_table .transactions_columns .table_row .status .requested {
      color: #204569; }
    .subscriptions_page .in_stock_table .transactions_columns .table_row .status .active {
      color: #2CC197; }
    .subscriptions_page .in_stock_table .transactions_columns .table_row .status .canceled {
      color: rgba(32, 69, 105, 0.5); }
  .subscriptions_page .in_stock_table .transactions_columns .table_row .link a {
    color: #3796F6;
    font-family: "MontSemiBold",sans-serif;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    margin-right: 20px; }
    .subscriptions_page .in_stock_table .transactions_columns .table_row .link a:hover {
      text-decoration: none;
      opacity: 0.7; }
    .subscriptions_page .in_stock_table .transactions_columns .table_row .link a span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .subscriptions_page .in_stock_table .transactions_columns .table_row .btn_info span {
    color: #204569;
    font-size: 14px;
    line-height: 18px; }
  .subscriptions_page .in_stock_table .transactions_columns .table_row .btn_info button {
    color: #E44B4B;
    font-family: "MontSemiBold",sans-serif;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase; }
    .subscriptions_page .in_stock_table .transactions_columns .table_row .btn_info button:hover {
      opacity: 0.7; }

.dialog_subscription .title {
  color: #204569;
  font-family: "MontBold",sans-serif;
  font-size: 32px;
  letter-spacing: -1px;
  line-height: 42px;
  margin-bottom: 32px; }

.dialog_subscription .descriptions {
  color: #204569;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px; }
  .dialog_subscription .descriptions span {
    font-family: "MontSemiBold",sans-serif; }

.dialog_subscription .btn_wrapper {
  display: flex;
  align-items: center;
  padding-top: 32px;
  margin-top: 32px;
  border-top: 1px solid rgba(32, 69, 105, 0.25); }
  .dialog_subscription .btn_wrapper button {
    margin-right: 16px; }

.search-results-wrapper {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 240px); }
  .search-results-wrapper .content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; }
    .search-results-wrapper .content-wrapper .results-wrapper {
      background-color: #ebf4fe;
      width: 100%;
      height: 100%;
      padding: 32px 32px 64px 32px; }
      .search-results-wrapper .content-wrapper .results-wrapper > h1 {
        font-size: 32px;
        color: #204569;
        font-family: "MontSemiBold";
        margin-bottom: 32px; }
      .search-results-wrapper .content-wrapper .results-wrapper .no-results {
        font-size: 24px;
        color: #204569;
        font-family: "MontSemiBold"; }
      .search-results-wrapper .content-wrapper .results-wrapper .results {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
        padding: 32px; }
    .search-results-wrapper .content-wrapper .search-pagination {
      padding: 32px 0;
      display: flex;
      align-items: center;
      justify-content: center; }

.search-element {
  display: flex;
  align-items: center;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(32, 69, 105, 0.25);
  margin-bottom: 32px;
  cursor: pointer; }
  .search-element:last-of-type {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0; }
  .search-element > img {
    width: 128px;
    height: 128px; }
  .search-element .details-wrapper {
    margin-left: 24px; }
    .search-element .details-wrapper .category {
      color: #3796f6;
      font-family: "MontSemiBold";
      font-size: 10px;
      margin-bottom: 2px;
      cursor: pointer; }
    .search-element .details-wrapper .name {
      font-family: "MontSemiBold";
      color: #204569;
      font-size: 16px;
      margin-bottom: 8px; }
    .search-element .details-wrapper .description {
      height: 32px;
      color: #204569;
      font-family: "MontLight";
      font-size: 10px;
      overflow: hidden;
      margin-bottom: 16px; }
  .search-element .row-wrapper {
    display: flex;
    align-items: center; }
    .search-element .row-wrapper > span {
      color: #fda45c;
      font-family: "MontBold";
      font-size: 14px;
      margin-right: 50px; }
    .search-element .row-wrapper .input-wrapper {
      display: flex;
      align-items: center;
      margin-right: 16px; }
      .search-element .row-wrapper .input-wrapper > input {
        width: 42px;
        height: 28px;
        border: 1px solid #dee4e9;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        font-family: "MontRegular";
        color: #204569; }
      .search-element .row-wrapper .input-wrapper > button {
        font-family: "MontRegular";
        color: #204569;
        font-size: 14px; }
        .search-element .row-wrapper .input-wrapper > button:disabled {
          opacity: 0.3; }
        .search-element .row-wrapper .input-wrapper > button:first-of-type {
          margin-right: 8px; }
        .search-element .row-wrapper .input-wrapper > button:last-of-type {
          margin-left: 8px; }
    .search-element .row-wrapper .add-cart {
      height: 36px;
      width: 62px;
      border-radius: 4px;
      background-color: #ebf4fe;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      color: #3796f6;
      font-family: "MontBold";
      text-transform: uppercase; }
      .search-element .row-wrapper .add-cart > img {
        margin-right: 2px; }

.product-details {
  min-height: calc(100vh - 240px);
  background-color: #ebf4fe;
  width: 100%;
  padding: 32px 64px 64px 64px;
  display: flex;
  flex-direction: column; }
  .product-details > h1 {
    margin-top: 20px;
    font-family: "MontSemiBold";
    color: #204569;
    font-size: 32px; }
  .product-details .content-wrapper {
    display: flex;
    margin-top: 32px; }
    .product-details .content-wrapper p {
      margin-bottom: 0; }
    .product-details .content-wrapper .main-info {
      width: 100%;
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
      padding: 32px;
      margin-right: 0px;
      display: flex; }
      .product-details .content-wrapper .main-info .text-wrapper {
        font-family: "MontRegular";
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
        .product-details .content-wrapper .main-info .text-wrapper .description {
          font-family: "MontRegular";
          font-size: 14px;
          padding-top: 14px;
          color: #204569; }
        .product-details .content-wrapper .main-info .text-wrapper .manufacturer {
          display: flex;
          align-items: center;
          color: #204569;
          margin-top: 16px; }
          .product-details .content-wrapper .main-info .text-wrapper .manufacturer .tag {
            font-family: "MontLight";
            font-size: 14px; }
          .product-details .content-wrapper .main-info .text-wrapper .manufacturer .name {
            font-family: "MontBold";
            font-size: 14px;
            color: #204569;
            margin-left: 4px; }
        .product-details .content-wrapper .main-info .text-wrapper .row-wrapper > span {
          font-size: 32px;
          margin-right: 65px; }
        .product-details .content-wrapper .main-info .text-wrapper .row-wrapper > button {
          height: 48px;
          width: 150px;
          border-radius: 4px;
          background-color: #3796f6;
          color: #ffffff;
          font-size: 14px;
          transition: all 0.5s; }
      .product-details .content-wrapper .main-info .photo {
        display: flex;
        flex-flow: row; }
        .product-details .content-wrapper .main-info .photo .more_img {
          display: flex;
          flex-direction: column; }
          .product-details .content-wrapper .main-info .photo .more_img .photo-detail {
            margin-bottom: 10px;
            cursor: pointer;
            border: 1.5px solid transparent;
            border-radius: 4px;
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center; }
            .product-details .content-wrapper .main-info .photo .more_img .photo-detail img {
              background-size: 100% 100%;
              width: 48px;
              height: 48px;
              border-radius: 4px;
              object-fit: cover; }
          .product-details .content-wrapper .main-info .photo .more_img .slick-slider {
            width: 90px;
            margin-top: 30px; }
          .product-details .content-wrapper .main-info .photo .more_img .slick-track > div > div > div {
            margin-bottom: 10px;
            cursor: pointer;
            border: 1.5px solid transparent;
            border-radius: 8px;
            width: 95% !important;
            height: 75px;
            display: flex !important;
            justify-content: center;
            align-items: center;
            text-align: center; }
            .product-details .content-wrapper .main-info .photo .more_img .slick-track > div > div > div img {
              background-size: 100% 100%;
              max-width: 73px;
              max-height: 73px;
              border-radius: 8px;
              object-fit: contain; }
          .product-details .content-wrapper .main-info .photo .more_img .slick-prev,
          .product-details .content-wrapper .main-info .photo .more_img .slick-next {
            height: 36px;
            width: 36px;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute; }
          .product-details .content-wrapper .main-info .photo .more_img .slick-prev {
            /* place button top right */
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHnSURBVHgB7ZhNSwJBGIDfmdnIwCyooEMfu9Qt6ONWUNT2Yzp0yl/SP8okoYOQVngIirX0EKQRtpS2O7PNWygmpru6unvwgcHZGcd5fGdml30JtJDJGNNiTJm1uD3LKERgAAhgJnO4OTMJeU3Tqs19pF5xHEe5yhVV4dgLMEToFy9WKit5XSd2QwhlMneFTW7xKAQARuyjtJhFKYoNGJmgZBAKPBqLPahYJ4ZhREombEMIUGzI0vI7qBASPuVhopywwJaqFSpPNsX1g5CAtxkKIWMk1I2RUDd8EUpdXK4mZQEfUKBPUOYsmTp1fi/j+3s799AHfUWoLsOFiApZzmW930j1LNQswyg1sfgh1ZNQq8zh/m4cix9S7Oj4RPUyoJ3Mrtw3S8uLr4zQ9ONT4RCl8JPIa1W2e/l9T0L/ydT7/ZByvWTXN7n5TjJ1sK11+XCs23lcC22srz3HpmKpTjLtpKbkGBwLLiHpW+MAPID/1u0EXr7bs9CgGT1cuzES6kb4hLiAKoQEYQmTCqaUICRwhZp0wrJDI1QrQ55ubWlvFlGKEDDooOta9WdT114W8pgSgYDAudEB642EVSLhKONzRXVsyAkrjAzK/ElYNZNIGJHxGVCZTEIM6r0fTzYepprcv7rcMs19327FKcfVtiglAAAAAElFTkSuQmCC);
            top: -43px;
            left: 50%;
            z-index: 10;
            -webkit-transform: translateX(-50%);
                    transform: translateX(-50%); }
          .product-details .content-wrapper .main-info .photo .more_img .slick-prev:before {
            content: none; }
          .product-details .content-wrapper .main-info .photo .more_img .slick-next {
            /* place button bottom right */
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIKSURBVHgB7ZhPS9xAFMDfvIm4ha2UaqEHbRPaW6Gtt5ZuaeOH6aGnemq/RaW9KH4J9eDRVRQ8CK5/8CAoWTUHwVU0Bt01mYnzRCUuuNlsXJPD/i47m7wwv7y8yZ/HoI5SyXomu7Q+T/h9HCEHbUACd3kg3N6nUDYMoxrex24GQRBoyxu2LgO/Hx4RvBC247wpmybzb4VIprS591F4Ig8pQBk7qwyskBTSBspMWjIEgsj39GzrNGaWZeUqLnyCDKD5sIKHp6BDRjhXiwkF46ldqnpQrWyk6wcZgW4zCBmjIxRFRyiK2EKraxsv2xF7QyyhkX+jfyampsfn5hffRsUuqJhJFTvyf+w3xKBpITpbxzn9IqXMz84t/G0kRTIzKkaoWOfEKcTJVNNCH96/2x/6VhjmiG4jqbAMxdIxdGyz8/AfP3/pzQa/ej1wxBku7ezuDZEU/TL1X1fb75MpfP28BTGIJdRIyt61nyeVIdjSuvUdWiCcDVQC9KaXVIZo+T5EE4Zr6iFkEgnVSz2EDKFBQq4FhkPjRLRcQ+2i83CNoiMURfaEhIQqZATpSRcl1yqQEYSGLj7x/MwI1Q6hjIODxrHHNBtShhxM06heFXXtoL9MLRFICZqbHGh827AqFgOt+4Wtdz1yw4oyQzJ3GlZhikUr190LOldNiHZ999PKpsVUU/VrqpIJ77sEKagnEYtbIegAAAAASUVORK5CYII=);
            left: 50%;
            top: 100% !important;
            z-index: 20;
            -webkit-transform: translateX(-50%);
                    transform: translateX(-50%); }
          .product-details .content-wrapper .main-info .photo .more_img .slick-next:before {
            content: none; }
          .product-details .content-wrapper .main-info .photo .more_img .slick-disabled {
            display: none !important; }
          .product-details .content-wrapper .main-info .photo .more_img .active {
            border: 1.5px solid #fda45c; }
            .product-details .content-wrapper .main-info .photo .more_img .active > img {
              width: 46px;
              height: 46px; }
        .product-details .content-wrapper .main-info .photo .img {
          cursor: pointer;
          margin: 0 32px;
          display: flex;
          justify-content: center; }
          .product-details .content-wrapper .main-info .photo .img img {
            width: 400px;
            height: 400px;
            background-size: 100% 100%;
            object-fit: cover;
            border-radius: 10px; }
    .product-details .content-wrapper .advertising {
      height: 464px;
      margin-left: 32px;
      width: 304px;
      border-radius: 10px;
      box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 64px 40px;
      background-color: #f6d1b2; }
      .product-details .content-wrapper .advertising .name {
        color: #c56519;
        font-family: "MontSemiBold";
        font-size: 20px;
        margin-bottom: 8px; }
      .product-details .content-wrapper .advertising .description {
        color: #c56519;
        font-size: 10px;
        font-family: "MontRegular";
        margin-bottom: 40px;
        text-align: center; }
      .product-details .content-wrapper .advertising .choose-plan {
        color: #c56519;
        font-size: 10px;
        font-family: "MontRegular";
        margin-bottom: 16px; }
      .product-details .content-wrapper .advertising .option {
        display: flex;
        align-items: center;
        border: 1px solid #fae5d3;
        border-radius: 4px;
        background-color: #fae5d3;
        height: 40px;
        width: 224px;
        margin-bottom: 8px;
        padding-left: 16px;
        cursor: pointer; }
        .product-details .content-wrapper .advertising .option:last-of-type {
          margin-bottom: 32px; }
        .product-details .content-wrapper .advertising .option > p {
          color: #c56519;
          font-family: "MontRegular";
          font-size: 14px;
          margin-left: 12px; }
        .product-details .content-wrapper .advertising .option .empty-radio {
          height: 16px;
          width: 16px;
          border: 1px solid #fda45c;
          opacity: 0.5;
          border-radius: 50%; }
      .product-details .content-wrapper .advertising > button {
        height: 36px;
        width: 104px;
        border-radius: 4px;
        background-color: #fda45c;
        display: flex;
        font-family: "MontSemiBold";
        align-items: center;
        text-transform: uppercase;
        justify-content: center;
        color: #ffffff;
        font-size: 10px; }
  .product-details .breadcrams {
    display: flex;
    align-items: center; }
    .product-details .breadcrams .breadcram-single {
      margin-right: 8px;
      display: flex;
      align-items: center;
      color: #3796f6;
      font-size: 10px;
      text-transform: uppercase;
      font-family: "MontSemiBold"; }
      .product-details .breadcrams .breadcram-single > img {
        margin-right: 4px;
        margin: block; }
      .product-details .breadcrams .breadcram-single > a {
        display: block;
        font-size: 10px;
        line-height: 10px; }
  .product-details .row-wrapper {
    display: flex;
    align-items: center; }
    .product-details .row-wrapper > span {
      color: #fda45c;
      font-family: "MontBold";
      font-size: 14px;
      margin-right: 10px; }
    .product-details .row-wrapper .input-wrapper {
      display: flex;
      align-items: center;
      margin-right: 16px; }
      .product-details .row-wrapper .input-wrapper > input {
        width: 42px;
        height: 28px;
        border: 1px solid #dee4e9;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        font-family: "MontRegular";
        color: #204569; }
      .product-details .row-wrapper .input-wrapper > button {
        font-family: "MontRegular";
        color: #204569;
        font-size: 14px; }
        .product-details .row-wrapper .input-wrapper > button:disabled {
          opacity: 0.3; }
        .product-details .row-wrapper .input-wrapper > button:first-of-type {
          margin-right: 8px; }
        .product-details .row-wrapper .input-wrapper > button:last-of-type {
          margin-left: 8px; }
    .product-details .row-wrapper .add-cart {
      height: 36px;
      width: 62px;
      border-radius: 4px;
      background-color: #ebf4fe;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      color: #3796f6;
      font-family: "MontBold";
      text-transform: uppercase; }
      .product-details .row-wrapper .add-cart > img {
        margin-right: 2px; }
  .product-details .recommended-wrapper {
    padding: 32px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
    margin-top: 32px; }
    .product-details .recommended-wrapper .head-text {
      color: #204569;
      font-size: 16px;
      font-family: "MontSemiBold";
      margin-bottom: 32px; }
  .product-details .slider-wrapper {
    width: 100%;
    margin-bottom: 32px;
    padding: 0 64px; }
    .product-details .slider-wrapper .slick-next {
      right: -64px;
      top: 90px;
      background: url(/static/media/forward-rec.09232637.svg) no-repeat;
      width: 48px;
      height: 48px; }
      .product-details .slider-wrapper .slick-next:before {
        content: ""; }
    .product-details .slider-wrapper .slick-prev {
      left: -64px;
      top: 90px;
      background: url(/static/media/back-rec.a4c0e1f7.svg) no-repeat;
      width: 48px;
      height: 48px;
      z-index: 1; }
      .product-details .slider-wrapper .slick-prev:before {
        content: ""; }
    .product-details .slider-wrapper .slider-el {
      height: 346px;
      width: 256px;
      display: flex;
      flex-direction: column;
      text-decoration: none;
      cursor: pointer;
      position: relative; }
      .product-details .slider-wrapper .slider-el > img {
        height: 192px;
        width: 256px;
        object-fit: cover;
        margin-bottom: 16px; }
      .product-details .slider-wrapper .slider-el .name {
        color: #204569;
        font-size: 14px;
        font-family: "MontSemiBold";
        margin-bottom: 8px; }
      .product-details .slider-wrapper .slider-el .description {
        color: #204569;
        font-size: 10px;
        font-family: "MontLight";
        max-height: 32px;
        overflow: hidden; }
      .product-details .slider-wrapper .slider-el .recomended {
        position: absolute;
        bottom: 0;
        width: 256px;
        display: flex;
        justify-content: space-between; }
        .product-details .slider-wrapper .slider-el .recomended > div {
          display: flex;
          align-items: center; }
    .product-details .slider-wrapper .slick-slide {
      display: flex;
      justify-content: center; }
      .product-details .slider-wrapper .slick-slide > div {
        width: 256px !important; }

.dialog_product_slider {
  background: transparent !important; }
  .dialog_product_slider.paper_custom_dialog {
    overflow: visible; }
  .dialog_product_slider .dialog_wrapper {
    padding: 0;
    background: transparent !important;
    overflow: visible; }
  .dialog_product_slider .dialog_close_button {
    top: 0;
    right: -185px;
    width: 36px;
    height: 36px;
    z-index: 999; }
    .dialog_product_slider .dialog_close_button img {
      display: none; }
  .dialog_product_slider .dialog_slider {
    width: 100%; }
  .dialog_product_slider .slick-prev {
    height: 100px;
    width: 36px;
    background: rgba(0, 0, 0, 0.3);
    font-size: 0;
    position: absolute;
    z-index: 20;
    right: calc(100% + 100vw / 12);
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
    left: auto;
    padding: 100px 50px;
    border-radius: 10px;
    transition: -webkit-transform 100ms;
    transition: transform 100ms;
    transition: transform 100ms, -webkit-transform 100ms; }
    .dialog_product_slider .slick-prev:before {
      position: absolute;
      content: "\F053";
      font-family: "FontAwesome", serif;
      font-size: 32px;
      line-height: 200px;
      text-align: center;
      height: 100%;
      width: 100%;
      right: 0;
      top: 0;
      opacity: 1;
      transition: opacity 250ms; }
    .dialog_product_slider .slick-prev:hover:before {
      opacity: 0.7; }
    .dialog_product_slider .slick-prev:active {
      -webkit-transform: translate(50%, -50%) scale(0.9);
              transform: translate(50%, -50%) scale(0.9); }
  .dialog_product_slider .slick-next {
    height: 100px;
    width: 36px;
    background: rgba(0, 0, 0, 0.3);
    font-size: 0;
    position: absolute;
    z-index: 20;
    left: calc(100% + 100vw / 12);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: auto;
    padding: 100px 50px;
    border-radius: 10px;
    transition: -webkit-transform 100ms;
    transition: transform 100ms;
    transition: transform 100ms, -webkit-transform 100ms; }
    .dialog_product_slider .slick-next:before {
      position: absolute;
      content: "\F054";
      font-family: "FontAwesome", serif;
      font-size: 32px;
      line-height: 200px;
      text-align: center;
      height: 100%;
      width: 100%;
      right: 0;
      top: 0;
      opacity: 1;
      transition: opacity 250ms; }
    .dialog_product_slider .slick-next:hover:before {
      opacity: 0.7; }
    .dialog_product_slider .slick-next:active {
      -webkit-transform: translate(-50%, -50%) scale(0.9);
              transform: translate(-50%, -50%) scale(0.9); }
  .dialog_product_slider .slick-slider {
    width: 100%;
    max-width: 100vh; }
  .dialog_product_slider .slider_items {
    display: flex !important;
    flex-flow: column;
    align-items: center; }
    .dialog_product_slider .slider_items .icon {
      width: 100%;
      height: 800px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      padding: 15px; }
      .dialog_product_slider .slider_items .icon img {
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        object-fit: contain; }
    .dialog_product_slider .slider_items span {
      display: flex;
      justify-content: center;
      padding: 2px 20px;
      margin-top: 5px;
      text-align: center;
      background: rgba(0, 0, 0, 0.8);
      text-shadow: 0 1px 2px #222;
      color: #fff;
      font-weight: bold;
      line-height: 24px; }

.dialog_advertising {
  display: flex;
  flex-direction: column; }
  .dialog_advertising .title {
    color: #204569;
    font-family: "MontBold",sans-serif;
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 42px;
    margin-bottom: 32px; }
  .dialog_advertising .descriptions {
    color: #204569;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 24px; }
    .dialog_advertising .descriptions span {
      font-family: "MontSemiBold",sans-serif; }
  .dialog_advertising .quantity {
    width: 104px;
    position: relative; }
    .dialog_advertising .quantity input {
      width: 104px;
      height: 48px;
      border-radius: 4px;
      border: 1px solid rgba(32, 69, 105, 0.3);
      box-sizing: border-box;
      color: #204569;
      font-family: "MontRegular",sans-serif;
      font-size: 14px;
      padding: 0 28px 0 16px; }
    .dialog_advertising .quantity > div {
      position: absolute;
      top: 2px;
      right: 3px;
      display: flex;
      flex-direction: column; }
      .dialog_advertising .quantity > div button {
        height: 20px;
        width: 20px;
        background-color: #EBF4FE;
        margin: 1px 0; }
        .dialog_advertising .quantity > div button span {
          height: 20px;
          width: 20px;
          position: relative;
          display: flex; }
          .dialog_advertising .quantity > div button span:before {
            position: absolute;
            content: ""; }
      .dialog_advertising .quantity > div .plus {
        border-radius: 0 2px 0 0; }
        .dialog_advertising .quantity > div .plus span:before {
          border: 4px solid transparent;
          border-bottom: 5px solid rgba(32, 69, 105, 0.5);
          left: 6px;
          top: 4px; }
      .dialog_advertising .quantity > div .minus {
        border-radius: 0 0 2px 0; }
        .dialog_advertising .quantity > div .minus span:before {
          border: 4px solid transparent;
          border-top: 5px solid rgba(32, 69, 105, 0.5);
          left: 6px;
          top: 7px; }
  .dialog_advertising .btn_wrapper {
    display: flex;
    align-items: center;
    padding-top: 32px;
    margin-top: 32px;
    border-top: 1px solid rgba(32, 69, 105, 0.25); }
    .dialog_advertising .btn_wrapper button {
      margin-right: 16px; }

.btn_whats_app {
  height: 64px;
  width: 64px;
  background-color: #2CC197;
  box-shadow: 0 4px 8px 0 rgba(25, 86, 72, 0.25);
  position: fixed;
  right: 32px;
  border-radius: 50%;
  bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999; }

.dialog_whats_app {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .dialog_whats_app .title {
    margin-bottom: 32px;
    color: #204569;
    font-family: "MontSemiBold",sans-serif;
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 42px; }
  .dialog_whats_app a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    margin-bottom: 12px;
    padding: 0 24px 0 32px;
    width: 100%;
    color: #204569;
    font-family: "MontSemiBold",sans-serif;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid rgba(54, 78, 101, 0.25);
    border-radius: 6px;
    text-decoration: none; }

.default_button_wrapper .default_button {
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: "MontBold", sans-serif;
  font-size: 14px;
  height: 48px;
  width: 176px;
  border-radius: 4px;
  text-transform: uppercase;
  box-shadow: 0 4px 8px 0 rgba(24, 113, 204, 0.25);
  transition: all 0.3s linear; }
  .default_button_wrapper .default_button:disabled {
    opacity: 0.7;
    box-shadow: none; }
  .default_button_wrapper .default_button .Mui-disabled {
    opacity: 0.7;
    box-shadow: none; }
  .default_button_wrapper .default_button.default_button_contained {
    background-color: #3796f6;
    color: #ffffff;
    border: 1px solid #3796f6; }
  .default_button_wrapper .default_button.default_button_outlined {
    color: #ffffff;
    border: 1px solid #ffffff; }
    .default_button_wrapper .default_button.default_button_outlined.cancel_btn {
      border: 1px solid #7f8fa4;
      color: #7f8fa4; }
    .default_button_wrapper .default_button.default_button_outlined.blue_btn {
      border: 1px solid #3796f6;
      color: #3796f6; }
      .default_button_wrapper .default_button.default_button_outlined.blue_btn:hover {
        background: rgba(55, 150, 246, 0.1); }
    .default_button_wrapper .default_button.default_button_outlined.delete_btn {
      border: 1px solid #d0021b;
      color: #d0021b; }

.default_button_wrapper .full_btn_width {
  width: 100%; }

.default_button_wrapper .Mui-disabled {
  opacity: 0.7;
  box-shadow: none; }

.auth_container {
  width: 100%;
  min-height: 600px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .auth_container form {
    width: 528px;
    position: relative; }
    .auth_container form .checkbox-row {
      display: "flex";
      align-items: center; }
    @media only screen and (max-width: 660px) {
      .auth_container form {
        max-width: 528px;
        width: 100%;
        padding: 0 15px; } }

.auth-block_head {
  font-weight: 600;
  font-size: 32px;
  color: #204569;
  letter-spacing: -1px;
  line-height: 42px;
  padding-bottom: 16px;
  font-family: "MontSemiBold", sans-serif; }

.auth-block_descriptions {
  color: #204569;
  font-size: 14px;
  margin-bottom: 64px;
  line-height: 22px;
  font-family: "MontRegular", sans-serif; }

.block_field {
  display: flex;
  flex-flow: column;
  position: relative; }
  .block_field .info_descriptions {
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
    opacity: 0.5;
    color: #204569;
    font-size: 10px; }
  .block_field .password_reset {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .block_field .password_reset a {
      color: #3796f6;
      font-size: 11px;
      margin-bottom: 8px;
      font-weight: 600; }
      .block_field .password_reset a:hover {
        text-decoration: underline; }
    .block_field .password_reset span {
      font-size: 10px;
      font-family: "MontSemiBold", sans-serif;
      line-height: 13px;
      padding-bottom: 8px;
      display: flex; }
  .block_field > span {
    font-size: 10px;
    font-family: "MontSemiBold", sans-serif;
    line-height: 13px;
    padding-bottom: 8px;
    display: flex; }
    .block_field > span p {
      padding-left: 3px;
      font-family: "MontRegular", sans-serif; }
  .block_field .back_error {
    color: #d0021b;
    background-color: transparent;
    bottom: 6px;
    left: unset; }
  .block_field textarea {
    font-family: "MontRegular", sans-serif;
    padding: 14px 16px;
    height: 149px;
    width: 100%;
    font-size: 14px;
    color: #204569;
    border-radius: 4px;
    border: 1px solid rgba(32, 69, 105, 0.3);
    box-sizing: border-box;
    resize: none; }
    .block_field textarea:focus {
      border: 1px solid #204569; }
    .block_field textarea::-webkit-input-placeholder {
      font-size: 14px;
      color: rgba(32, 69, 105, 0.5);
      line-height: 20px; }
    .block_field textarea::placeholder {
      font-size: 14px;
      color: rgba(32, 69, 105, 0.5);
      line-height: 20px; }

.block_custom_auth_page {
  position: relative;
  width: 528px; }
  .block_custom_auth_page .block_key {
    padding: 27px 0 47px; }

.block_key {
  border-radius: 10px;
  background-color: #ebf4fe;
  padding: 35px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px; }

.auth_dialog_wrapper .title {
  font-family: "MontSemiBold", sans-serif;
  padding-bottom: 32px;
  font-size: 32px;
  letter-spacing: -1px;
  line-height: 42px; }

.auth_dialog_wrapper .descriptions {
  font-size: 14px;
  padding-bottom: 32px;
  font-family: "MontRegular", sans-serif;
  color: rgba(32, 69, 105, 0.5); }

.auth_dialog_wrapper .default_button_wrapper a {
  box-shadow: none;
  height: 36px;
  width: 104px;
  font-size: 10px;
  border-radius: 2px; }

.auth_btn_wrapper {
  padding-top: 34px;
  display: flex;
  justify-content: center;
  padding-bottom: 30px; }

.back_error {
  padding-bottom: 100px;
  color: #d0021b;
  position: absolute;
  left: 120px;
  font-family: "MontRegular", sans-serif !important; }

.back_error.third {
  left: 165px; }

.back_step {
  font-family: "MontSemiBold", sans-serif;
  font-size: 12px;
  position: absolute;
  left: 0;
  top: -17px;
  color: #3796f6;
  text-transform: uppercase;
  align-items: center;
  display: flex; }
  .back_step img {
    margin-right: 8px;
    margin-top: -2px; }

.block_custom_field {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 660px) {
    .block_custom_field {
      flex-direction: column; } }
  .block_custom_field .block-input {
    width: 250px; }
    @media only screen and (max-width: 660px) {
      .block_custom_field .block-input {
        width: 100%; } }
  .block_custom_field .custom_field_phone {
    width: 250px; }
  .block_custom_field .captcha_block > div > div > div {
    height: 48px !important;
    width: 176px !important; }
    .block_custom_field .captcha_block > div > div > div > div {
      height: 48px !important;
      width: 176px !important; }
  .block_custom_field .captcha_block iframe {
    -webkit-transform: scale(0.58);
            transform: scale(0.58);
    margin-left: -64px;
    margin-top: 5px; }
    .block_custom_field .captcha_block iframe #rc-anchor-alert {
      display: none !important; }
    .block_custom_field .captcha_block iframe .rc-anchor-pt {
      display: none !important; }

.g-recaptcha {
  -webkit-transform: scale(0.77);
          transform: scale(0.77);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 0; }

.info_auth {
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: center;
  display: flex; }
  .info_auth span {
    color: #204569;
    font-size: 14px;
    padding-right: 15px; }
  .info_auth a {
    text-transform: uppercase;
    color: #3796f6;
    font-family: "MontBold", sans-serif;
    font-size: 14px; }

.auth_bg {
  background: url(/static/media/graph.d08d2bb2.svg) no-repeat 50% 50%;
  -webkit-background-size: cover;
  background: #ebf4fe;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative; }
  @media only screen and (max-width: 960px) {
    .auth_bg {
      display: none; } }

.bgImg {
  width: 100%;
  background-size: 100% 100%; }

.auth_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative; }

.auth-box {
  width: 100%;
  height: 100%;
  display: flex; }
  .auth-box > :first-child {
    width: 30%; }
  .auth-box > :last-child {
    width: 70%;
    background-color: #fff; }
    @media only screen and (max-width: 960px) {
      .auth-box > :last-child {
        width: 100%; } }

.auth_logo {
  position: absolute;
  left: calc(50% - 139px);
  top: 15%; }
  .auth_logo img {
    height: 86px;
    width: 284px;
    background-size: 100% 100%; }

.captcha_block.smallscreen,
.auth-header {
  display: none; }

@media only screen and (max-width: 2560px) {
  .auth-form {
    margin-top: 260px; } }

@media only screen and (max-width: 1601px) {
  .auth-form {
    margin-top: 150px; } }

@media only screen and (max-width: 1440px) {
  .auth-form {
    margin-top: 140px; } }

@media only screen and (max-width: 1024px) {
  .auth-form {
    margin-top: 100px; } }

@media only screen and (max-width: 960px) {
  .info_auth {
    display: none; }
  .block_custom_auth_page .block_key {
    background: #fff; }
  .auth-form {
    margin-top: 0px; }
  .default_dialog_root .paper_custom_dialog {
    max-width: 720px !important;
    min-width: inherit; }
  .auth_dialog_wrapper .title {
    font-size: 24px;
    padding-bottom: 0; }
  .block_custom_auth_page {
    max-width: 528px;
    width: 100%;
    padding: 0 15px; }
  .auth_content .back_step {
    top: 74px; }
  .auth-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 56px;
    margin-top: 32px; }
    .auth-header.steps {
      align-self: flex-start;
      margin-bottom: 80px; }
    .auth-header .auth_logo {
      position: relative;
      left: 0; }
      .auth-header .auth_logo img {
        width: 71px;
        height: 24px; }
    .auth-header .sign-in-button {
      font-weight: 600; } }

@media only screen and (max-width: 660px) {
  .captcha_block.fullscreen {
    display: none; }
  .back_step {
    left: 15px; }
  .captcha_block.smallscreen {
    display: flex; }
  .auth_btn_wrapper .default_button_wrapper .default_button {
    width: 288px; }
  span.checkbox-txt,
  .auth-block_descriptions {
    font-size: 12px; }
  .auth-block_descriptions {
    margin-bottom: 32px; }
  .auth-block_head {
    font-size: 24px; }
  .auth_dialog_wrapper .title {
    font-size: 16px;
    padding-bottom: 0; }
  .checkbox-row {
    align-items: flex-start !important; }
    .checkbox-row span {
      padding: 0;
      padding-right: 9px;
      margin-bottom: 10px; } }


.home
{
	width: 100%;
	height: 260px;
	background: transparent;
}
.home_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.home_overlay
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #cde4f1;
	background: linear-gradient(#FFFFFF, #cde4f1);
	opacity: 0.9;
}
.home_content
{
	width: 100%;
	height: 100%;
}






.shop
{
	background: #FFFFFF;
	padding-top: 80px;
	padding-bottom: 139px;
}
.shop_content
{
	width: 100%;
}
.shop_bar
{
	border-bottom: solid 1px #dadada;
	padding-bottom: 14px;
	z-index: 1;
}
.shop_product_count
{
	font-size: 14px;
	font-weight: 500;
	float: left;
}
.shop_product_count span
{
	color: #0e8ce4;
}
.shop_sorting
{
	float: right;
}
.shop_sorting span
{
	display: inline-block;
	font-weight: 500;
}
.shop_sorting > ul
{
	display: inline-block;
	position: relative;
	margin-left: 6px;
}
.shop_sorting ul li
{
	color: rgba(0,0,0,0.5);
	cursor: pointer;
}
.shop_sorting ul li:hover
{
	color: rgba(0,0,0,0.8);
}
.shop_sorting ul li i
{
	display: inline-block;
	font-size: 10px;
	color: rgba(0,0,0,0.5);
	margin-left: 4px;
	vertical-align: middle;
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
}
.shop_sorting ul li ul
{
	display: block;
	position: absolute;
	top: calc(100% + 15px);
	right: 0;
	text-align: right;
	background: #FFFFFF;
	width: auto;
	padding-top: 15px;
	visibility: hidden;
	opacity: 0;
	box-shadow: 0px 10px 25px rgba(0,0,0,0.1);
	transition: all 200ms ease;
}
.shop_sorting ul li:hover ul
{
	top: 100%;
	visibility: visible;
	opacity: 1;
}
.shop_sorting ul li ul li
{
	white-space: nowrap;
	padding-right: 13px;
	padding-left: 20px;
	margin-bottom: 5px;
	border-bottom: solid 1px #f2f2f2;
	padding-top: 5px;
	padding-bottom: 9px;
}
.shop_sorting ul li ul li:last-child
{
	border-bottom: none;
}



.product_grid
{
	-webkit-transform: translateX(-20px);
	transform: translateX(-20px);
	width: calc(100% + 40px);
}
.product_grid_border
{
	display: block;
	position: absolute;
	top: 0px;
	right: 0px;
	width: 3px;
	height: 100%;
	background: #FFFFFF;
	z-index: 1;
}
.product_item
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 20%;
	background: #FFFFFF;
	cursor: pointer;
	padding-top: 40px;
	padding-bottom: 24px;
	text-align: center;
	float: left;
}
.product_border
{
	display: block;
	position: absolute;
	top: 52px;
	right: 1px;
	width: 1px;
	height: calc(100% - 71px);
	background: #e5e5e5;
}
.product_image
{
	width: 100%;
	height: 115px;
}
.product_image img
{
	display: block;
	position: relative;
	max-width: 100%;
}
.product_content
{
	width: 100%;
}
.product_price
{
	font-size: 16px;
	font-weight: 500;
	margin-top: 25px;
}
.product_item.discount
{
	color: #df3b3b;
}
.product_price span
{
	position: relative;
	font-size: 12px;
	font-weight: 400;
	color: rgba(0,0,0,0.6);
	margin-left: 10px;
}
.product_price span::after
{
	display: block;
	position: absolute;
	top: 6px;
	left: -2px;
	width: calc(100% + 4px);
	height: 1px;
	background: #8d8d8d;
	content: '';
}
.product_name
{
	margin-top: 4px;
	overflow: hidden;
}
.product_name div
{
	width: 100%;

}
.product_name div a
{
	font-size: 14px;
	font-weight: 400;
	color: #000000;
	white-space: nowrap;
	transition: all 200ms ease;
}
.product_name div a:hover
{
	color: #0e8ce4;
}
.product_fav
{
	position: absolute;
	top: 33px;
	right: 12px;
	width: 36px;
	height: 36px;
	background: #FFFFFF;
	box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
	border-radius: 50%;
	visibility: hidden;
	opacity: 0;
	transition: all 200ms ease;
}
.product_fav:hover
{
	box-shadow: 0px 1px 5px rgba(0,0,0,0.3);
}
.product_fav i
{
	display: block;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	color: #cccccc;
	line-height: 36px;
	pointer-events: none;
	z-index: 0;
	transition: all 200ms ease;
}
.product_fav.active i
{
	color: red;
}
.product_item:hover .product_fav
{
	visibility: visible;
	opacity: 1;
}
.product_marks
{
	display: block;
	position: absolute;
	top: 33px;
	left: 24px;
	transition: all 200ms ease;
}
.product_mark
{
	display: inline-block;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	color: #FFFFFF;
	text-align: center;
	line-height: 36px;
	font-size: 12px;
}
.product_new
{
	display: none;
	background: #0e8ce4;
	visibility: hidden;
	opacity: 0;
}
.product_discount
{
	display: none;
	background: #df3b3b;
	visibility: hidden;
	opacity: 0;
}
.product_item.is_new .product_new,
.product_item.discount .product_discount
{
	display: inline-block;
	visibility: visible;
	opacity: 1;
}




.shop_page_nav
{
	width: 100%;
	height: 50px;
	margin-top: 80px;
}
.page_prev,
.page_next
{
	width: 50px;
	height: 100%;
	border: solid 1px #e5e5e5;
	border-radius: 5px;
	cursor: pointer;
}
.page_prev i,
.page_next i
{
	font-size: 12px;
	color: #e5e5e5;
	transition: all 200ms ease;
}
.page_prev:hover i,
.page_next:hover i
{
	color: #636363;
}
.page_nav
{
	border: solid 1px #e5e5e5;
	border-radius: 5px;
	margin-left: 15px;
	margin-right: 15px;
}
.page_nav li
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	border-right: solid 1px #e5e5e5;
	cursor: pointer;
}
.page_nav li a
{
	font-weight: 500;
	color: rgba(0,0,0,0.7);
	transition: all 200ms ease;
}
.page_nav li:hover a
{
	color: #0e8ce4;
}
.page_nav li:last-child
{
	border-right: none;
}



.shop_sidebar
{
	width: 100%;
	-webkit-transform: translateX(-37px);
	transform: translateX(-37px);
}
.sidebar_title
{
	font-size: 18px;
	font-weight: 500;
}
.sidebar_categories
{
	margin-top: 37px;
}
.sidebar_categories li
{
	margin-bottom: 7px;
}
.sidebar_categories li a
{
	color: rgba(0,0,0,0.5);
	transition: all 200ms ease;
}
.sidebar_categories li a:hover
{
	color: #0e8ce4;
}
.filter_by_section
{
	margin-top: 45px;
}
.filter_price
{
	margin-top: 28px;
}
.slider_range
{
	max-width: 195px;
}
.filter_price p
{
	display: inline-block;
	font-size: 12px;
	font-weight: 500 !important;
	color: rgba(0,0,0,0.5);
	margin-bottom: 0px;
}
.sidebar_subtitle
{
	font-size: 14px;
	font-weight: 500;
	margin-top: 25px;
}
.filter_price p input
{
	font-size: 12px;
	font-weight: 500 !important;
	color: rgba(0,0,0,0.5)
}
.amount
{
	margin-top: 18px;
}
.color_subtitle
{
	margin-top: 27px;
}
.colors_list
{
	margin-top: 14px;
}
.color
{
	display: inline-block;
	margin-right: 6px;
}
.color a
{
	display: block;
	width: 20px;
	height: 20px;
	border-radius: 50%;
}
.brands_subtitle
{
	margin-top: 27px;
}
.brands_list
{
	margin-top: 12px;
}
.brand
{
	margin-bottom: 7px;
}
.brand a
{
	color: rgba(0,0,0,0.5);
	transition: all 200ms ease;
}
.brand a:hover
{
	color: #0e8ce4;
}

