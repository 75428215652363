.panel_wrapper {
    border-right: 2px solid rgba(55, 150, 246, 0.1);
    max-width: 158px;
    margin-top: -80px;
    width: 100%;
    background: #ffffff;
    box-sizing: border-box;
    display: flex;
    min-height: 100vh;
}

.panel {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    .logo_panel {
        margin-top: 32px;
        margin-bottom: 68px;
        img {
            background-size: 100% 100%;
            height: 28px;
            width: 94px;
        }
    }
    .block_link {
        display: flex;
        flex-flow: column;
        a {
            width: 64px;
            height: 64px;
            background: rgba(54, 78, 101, 0.2);
            font-size: 18px;
            border-radius: 10px;
            text-decoration: none;
            margin-bottom: 45px;
            position: relative;
            .panel_title {
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translate(-50%, 5px);
                text-align: center;
                font-size: 12px;
                line-height: 14px;
                color: #444;
                transition: color 200ms;
            }
            &:hover {
                background: #3796f6;
                .panel_title { 
                    color: #222;
                }
            }
            .cart-count {
                position: absolute;
                width: 22px;
                height: 22px;
                border-radius: 50%;
                background-color: #c32e45;
                font-family: "Rubik", sans-serif;
                font-size: 12px;
                color: #ffffff;
                top: -7px;
                right: -7px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .active {
            box-shadow: 0 4px 8px 0 rgba(24, 113, 204, 0.25);
            background: #3796f6;
            .panel_title { 
                color: #000;
            }
        }
    }
}
