@charset "UTF-8";
.activity_page {
  display: flex;
  flex-flow: column; }
  .activity_page h3 {
    padding: 20px 0; }
  .activity_page .pagination_info_wrapper {
    padding-bottom: 20px;
    border-top: 1px solid rgba(32, 69, 105, 0.25); }
  .activity_page .activity_block {
    padding-bottom: 10px; }
  .activity_page .in_stock_table .table_header .table_row {
    height: 50px !important; }
  .activity_page .in_stock_table .table_body .table_row:last-of-type {
    border-bottom: 1px solid rgba(32, 69, 105, 0.25); }
  .activity_page .in_stock_table .transactions_columns .table_row {
    height: auto;
    flex-flow: column; }
    .activity_page .in_stock_table .transactions_columns .table_row .row {
      width: 100%;
      display: flex;
      height: 60px;
      align-items: center;
      padding: 0 40px; }
      .activity_page .in_stock_table .transactions_columns .table_row .row.highlighted {
        background-color: rgba(235, 244, 254, 0.5);
        border-top: 1px solid rgba(55, 150, 246, 0.25);
        border-bottom: 1px solid rgba(55, 150, 246, 0.25);
        position: relative;
        animation: pulse 1.5s infinite; }

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(55, 150, 246, 0.25);
    box-shadow: 0 0 0 0 rgba(55, 150, 246, 0.25); }
  70% {
    -moz-box-shadow: 0 0 0 8px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 8px rgba(204, 169, 44, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(55, 150, 246, 0.25); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }
      .activity_page .in_stock_table .transactions_columns .table_row .row > :first-child {
        width: 30% !important; }
        @media only screen and (max-width: 1200px) {
          .activity_page .in_stock_table .transactions_columns .table_row .row > :first-child {
            width: 25% !important; } }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :first-child div {
          white-space: nowrap;
          overflow: hidden;
          padding-right: 15px;
          text-overflow: ellipsis; }
      .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) {
        width: 40%;
        display: flex;
        align-items: center; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) > span {
          text-transform: capitalize;
          color: #204569;
          font-family: "MontSemiBold";
          font-size: 14px;
          margin-right: 40px;
          display: flex;
          flex-direction: column; }
          .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) > span.row-alighned {
            flex-direction: row;
            align-items: center; }
            .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) > span.row-alighned .refunded_status_label {
              margin-left: 16px;
              padding: 8px;
              border-radius: 14px;
              background-color: rgba(143, 162, 180, 0.1); }
          @media only screen and (max-width: 992px) {
            .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) > span {
              font-size: 12px; } }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) > button {
          text-transform: uppercase;
          color: #3796f6;
          font-family: "MontSemiBold";
          font-size: 10px; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) .download {
          margin-left: 20px;
          position: relative; }
          .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) .download::before {
            content: "●";
            color: #3796f6;
            position: absolute;
            left: -12px;
            top: 0px;
            font-size: 8px; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(2) > div {
          display: flex;
          align-items: start;
          justify-content: center; }
      .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) {
        width: 30%;
        display: flex;
        align-items: center;
        position: relative; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3).with-padding {
          padding-left: 182px; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) button {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) div {
          height: 24px;
          width: 36px;
          border-radius: 2px;
          background-color: #ebf4fe;
          display: flex;
          justify-content: center;
          align-items: center; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) .confirm-btn {
          max-width: 89px;
          width: 100%;
          height: 32px;
          border-radius: 4px;
          background-color: #e9f8f4;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #2cc197;
          font-size: 10px;
          font-family: "MontBold";
          text-transform: uppercase; }
          .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) .confirm-btn.alone {
            width: 84px; }
          .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) .confirm-btn > img {
            margin-right: 4px; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) .reject-btn {
          max-width: 89px;
          width: 100%;
          height: 32px;
          border-radius: 4px;
          background-color: #fcecec;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #e44b4b;
          font-size: 10px;
          font-family: "MontBold";
          text-transform: uppercase;
          align-self: center; }
          .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) .reject-btn > img {
            margin-right: 4px; }
        .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) .download-btn {
          width: 89px;
          height: 32px;
          border-radius: 4px;
          font-size: 10px;
          font-family: "MontBold";
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ebf4fe;
          color: #3796f6; }
          .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) .download-btn > img {
            margin-right: 4px; }
    .activity_page .in_stock_table .transactions_columns .table_row .info {
      transition: all 0.2s linear;
      height: 0;
      display: flex;
      width: 100%;
      align-items: center;
      border-radius: 4px;
      background-color: #ebf4fe;
      margin: 0;
      overflow: hidden; }
      .activity_page .in_stock_table .transactions_columns .table_row .info .row_item > span {
        opacity: 0.5;
        color: #204569;
        font-family: "MontLight", sans-serif;
        font-size: 10px;
        line-height: 14px; }
      .activity_page .in_stock_table .transactions_columns .table_row .info > :first-child {
        width: 36%;
        display: flex;
        align-items: flex-end; }
        .activity_page .in_stock_table .transactions_columns .table_row .info > :first-child span {
          display: flex;
          align-items: flex-end;
          margin-right: 12px;
          padding-left: 40px; }
        .activity_page .in_stock_table .transactions_columns .table_row .info > :first-child a {
          margin-bottom: -2px;
          color: #3796f6;
          font-family: "MontSemiBold", sans-serif;
          font-size: 14px;
          line-height: 18px; }
          @media only screen and (max-width: 992px) {
            .activity_page .in_stock_table .transactions_columns .table_row .info > :first-child a {
              font-size: 12px; } }
      .activity_page .in_stock_table .transactions_columns .table_row .info > :nth-child(2) {
        width: 16%;
        display: flex;
        align-items: center; }
        .activity_page .in_stock_table .transactions_columns .table_row .info > :nth-child(2) span {
          display: flex;
          align-items: center;
          margin-right: 12px; }
      .activity_page .in_stock_table .transactions_columns .table_row .info > :nth-child(3) {
        width: 16%;
        display: flex; }
        .activity_page .in_stock_table .transactions_columns .table_row .info > :nth-child(3) span {
          display: flex;
          align-items: center;
          margin-right: 12px; }
    .activity_page .in_stock_table .transactions_columns .table_row .info_open {
      margin-bottom: 20px;
      height: 66px; }

.activity_block {
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
  padding: 32px 00px;
  border-radius: 10px; }
  .activity_block .waiting :disabled {
    cursor: not-allowed; }
  .activity_block .waiting.notification_for_file {
    position: relative; }
    .activity_block .waiting.notification_for_file:before {
      content: attr(notification) "";
      display: none;
      position: absolute;
      bottom: calc(100% + 5px);
      left: 80%;
      background: rgba(51, 51, 51, 0.9);
      width: 100px;
      text-align: left;
      padding: 5px 10px;
      border-radius: 5px 5px 5px 0; }
    .activity_block .waiting.notification_for_file:hover:before {
      display: block; }
    .activity_block .waiting.notification_for_file.no_tooltip:before {
      display: none; }
  .activity_block .waiting .MuiCircularProgress-root {
    height: 12px !important;
    width: 12px !important;
    margin-right: 5px; }
    .activity_block .waiting .MuiCircularProgress-root circle {
      stroke-width: 5; }
  .activity_block .select_wrapper {
    margin-bottom: 30px;
    margin-left: 40px; }
  .activity_block .panel_activity {
    padding-bottom: 12px; }
  .activity_block .activity_info_wrapper > div > div {
    border-bottom: 1px solid rgba(32, 69, 105, 0.25);
    display: flex;
    padding-top: 20px;
    flex-flow: column; }
    .activity_block .activity_info_wrapper > div > div > span {
      opacity: 0.5;
      font-family: "MontLight", sans-serif;
      font-size: 10px;
      line-height: 13px;
      padding-bottom: 8px;
      display: flex; }
    .activity_block .activity_info_wrapper > div > div p {
      padding-bottom: 20px;
      font-size: 14px; }
      @media only screen and (max-width: 992px) {
        .activity_block .activity_info_wrapper > div > div p {
          font-size: 12px; } }
      .activity_block .activity_info_wrapper > div > div p span {
        font-family: "MontSemiBold", sans-serif; }
  .activity_block .activity_info_wrapper > div:last-of-type > div {
    border-bottom: none; }

#proformaTitle {
  text-align: center;
  vertical-align: middle;
  font-style: bold;
  font-weight: bold; }

#clientDesc {
  font-style: bold;
  font-weight: bold; }

.table-bordered {
  border: 1px solid black; }
  .table-bordered .delete_item_btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    background-color: rgba(255, 0, 0, 0.1);
    transition: background-color 250ms; }
    .table-bordered .delete_item_btn::after, .table-bordered .delete_item_btn::before {
      content: "";
      display: block;
      width: 20px;
      height: 4px;
      border-radius: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: #ff7272;
      transition: background-color 250ms; }
    .table-bordered .delete_item_btn::before {
      transform: translate(-50%, -50%) rotate(45deg); }
    .table-bordered .delete_item_btn::after {
      transform: translate(-50%, -50%) rotate(-45deg); }
    .table-bordered .delete_item_btn:hover {
      background-color: rgba(255, 0, 0, 0.5); }
      .table-bordered .delete_item_btn:hover::after, .table-bordered .delete_item_btn:hover::before {
        background-color: red; }

.modelBody .table-bordered tr td {
  border: 1px solid black; }

thead tr th,
.btn-sm {
  font-style: bold;
  font-weight: bold; }

#my-table {
  background-color: #ebf4fe; }
  #my-table tr {
    background-color: #ebf4fe; }
    #my-table tr td {
      background-color: #ebf4fe; }
      #my-table tr td .lol {
        background-color: #ebf4fe; }

p.without-margin,
.download-txt-block {
  margin-bottom: 0 !important; }

.download-img-block {
  margin-right: 0 !important; }

.download-txt-block {
  color: #204569;
  font-size: 10px;
  text-transform: uppercase;
  font-family: "MontSemiBold", sans-serif;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  margin-left: 4px;
  align-self: center;
  padding-top: 5px;
  cursor: pointer; }
  .download-txt-block.with-margin {
    margin-right: 92px; }
    .download-txt-block.with-margin.arrow {
      margin-right: 80px; }
  .download-txt-block:hover {
    text-decoration: none;
    color: #204569; }

.activity_dialog {
  padding: 48px 64px; }
  .activity_dialog-title span {
    color: #204569;
    font-size: 32px;
    line-height: 42px;
    font-family: "MontSemiBold", sans-serif; }
  .activity_dialog-txt {
    color: #204569;
    margin-top: 32px;
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 22px;
    font-family: "MontSemiBold", sans-serif; }
    @media only screen and (max-width: 992px) {
      .activity_dialog-txt {
        font-size: 12px; } }
  .activity_dialog .btn_wrapper {
    margin-top: 32px;
    padding-top: 32px;
    display: flex;
    border-top: 1px solid rgba(32, 69, 105, 0.5); }
    .activity_dialog .btn_wrapper button:first-child {
      margin-right: 16px; }

.block_field.stamp-block .stamp-title {
  font-size: 10px; }

.block_field.stamp-block .activity_dialog-txt {
  margin-bottom: 24px !important;
  white-space: break-spaces; }

.block_field.stamp-block .blue_btn.upload {
  height: 48px;
  width: 116px;
  text-transform: none;
  font-size: 14px;
  font-family: "MontSemiBold", sans-serif;
  cursor: pointer; }
  @media only screen and (max-width: 992px) {
    .block_field.stamp-block .blue_btn.upload {
      font-size: 12px; } }

.stamp-block label {
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px; }
  @media only screen and (max-width: 992px) {
    .stamp-block label {
      font-size: 12px; } }

.stamp-block-row {
  display: flex; }

.stamp-block-btn {
  background-color: transparent !important;
  width: 15px !important;
  margin-top: 0 !important;
  margin-left: 6px !important; }

.stamp-block-title {
  text-overflow: ellipsis;
  padding-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  color: #3796f6 !important;
  opacity: 1 !important;
  font-family: "MontSemiBold", sans-serif;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  max-width: 186px;
  display: flex;
  align-self: center;
  margin-bottom: 0 !important;
  margin-left: 6px !important;
  font-size: 14px; }
  @media only screen and (max-width: 992px) {
    .stamp-block-title {
      font-size: 12px; } }

.download-arrow {
  transform: rotate(180deg);
  margin-right: 80px;
  margin-left: 5px;
  transition: all 0.3s;
  cursor: pointer; }
  .download-arrow.open {
    transition: all 0.3s;
    transform: rotate(0deg); }

.download_block {
  margin-right: 25px; }

.dropdown-list {
  display: none !important;
  flex-direction: column;
  position: absolute;
  max-width: 147px;
  width: 100%;
  padding: 16px;
  background: #fff;
  box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
  border-radius: 4px;
  z-index: 1000;
  top: 30px; }
  .dropdown-list a {
    color: #204569;
    text-decoration: none;
    font-family: "MontSemiBold", sans-serif;
    border: none; }
    .dropdown-list a:hover {
      background: none;
      color: rgba(33, 69, 105, 0.7); }
  .dropdown-list.open {
    display: flex !important; }

.row_item span.rejected_status_label {
  color: #e44b4b;
  line-height: 13px !important;
  font-size: 10px !important;
  font-family: "MontSemiBold", sans-serif !important; }

.row_item span.refunded_status_label {
  color: #8fa2b4 !important;
  line-height: 13px !important;
  font-size: 10px !important;
  font-family: "MontSemiBold", sans-serif !important; }

.refunded_status_label {
  color: #8fa2b4 !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important; }
  .refunded_status_label img {
    margin-right: 5px;
    height: 12px !important;
    width: 12px !important; }

.activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) div {
  max-width: 200px !important;
  width: 100% !important;
  background-color: #fff;
  padding: 12px 16px !important;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.01);
  line-height: 20px;
  font-family: "MontSemiBold", sans-serif !important;
  font-size: 14px;
  color: #204569; }
  @media only screen and (max-width: 992px) {
    .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) div {
      font-size: 12px; } }
  .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) div::before {
    display: none; }
  .activity_page .in_stock_table .transactions_columns .table_row .row > :nth-child(3) div::after {
    display: none; }

.table_row-elem {
  display: none; }

@media only screen and (max-width: 1200px) {
  .activity_page .row_item.with-padding {
    padding-left: 110px !important; }
  .activity_page .download-arrow {
    margin-right: 10px; }
  .activity_page .table_row .row .row_item.row_item-date {
    width: 25% !important;
    font-size: 10px !important; }
  .row_item-date {
    font-size: 10px !important; } }

@media only screen and (max-width: 992px) {
  .row_item.with-padding {
    padding-left: 0 !important; }
  .download-arrow {
    margin-right: 10px; } }

@media only screen and (max-width: 900px) {
  .table_header,
  .table_row {
    display: none !important; }
  .table_row-elem {
    display: flex; }
  .search-wrapper,
  .cart-wrapper {
    display: none; }
  .drop-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    width: 208px;
    border-radius: 4px;
    z-index: 100;
    padding: 16px 24px;
    top: 50px;
    right: 0px; }
    .drop-menu-btn {
      display: flex;
      align-items: center;
      margin-bottom: 10px; }
      .drop-menu-btn.border {
        border: none !important;
        border-bottom: 1.2px solid rgba(32, 69, 105, 0.25) !important;
        width: 100%;
        padding-bottom: 12px; }
      .drop-menu-btn img {
        margin-right: 8px; }
      .drop-menu-btn span {
        color: #204569;
        font-size: 12px;
        font-family: "MontRegular";
        text-decoration: none; }
    .drop-menu > div {
      font-family: "MontRegular";
      color: #8fa2b4;
      font-size: 12px;
      cursor: pointer;
      border-top: 1px solid rgba(32, 69, 105, 0.15);
      padding-top: 16px; }
  .table_row-elem {
    padding: 24px 0;
    border-bottom: 1px solid rgba(32, 69, 105, 0.25) !important;
    display: flex;
    flex-direction: column; }
    .table_row-elem:first-child {
      border-top: 1px solid rgba(32, 69, 105, 0.25) !important; }
    .table_row-elem.highlighted {
      background-color: rgba(235, 244, 254, 0.5);
      border-top: 1px solid rgba(55, 150, 246, 0.25);
      border-bottom: 1px solid rgba(55, 150, 246, 0.25);
      position: relative;
      animation: pulse 1.5s infinite; }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(55, 150, 246, 0.25);
      box-shadow: 0 0 0 0 rgba(55, 150, 246, 0.25); }
    70% {
      -moz-box-shadow: 0 0 0 8px rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 8px rgba(204, 169, 44, 0); }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(55, 150, 246, 0.25); }
    70% {
      -webkit-box-shadow: 0 0 0 8px rgba(204, 169, 44, 0); }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }
  .table_row-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 40px; }
  .table_row-status {
    display: flex;
    padding: 0 40px; }
    .table_row-status span {
      text-transform: capitalize;
      color: #204569;
      font-family: "MontSemiBold";
      font-size: 14px;
      margin-right: 40px;
      display: flex;
      flex-direction: row; }
      .table_row-status span span {
        margin-left: 8px;
        color: #e44b4b;
        line-height: 13px !important;
        font-size: 10px !important;
        font-family: "MontSemiBold";
        display: flex;
        flex-direction: column;
        justify-content: center; }
  .table_row-buttons {
    display: flex;
    margin-top: 20px;
    padding: 0 40px; }
    .table_row-buttons .confirm-btn {
      width: 120px;
      height: 32px;
      border-radius: 4px;
      background-color: #e9f8f4;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #2cc197;
      font-size: 10px;
      font-family: "MontBold";
      text-transform: uppercase; }
      .table_row-buttons .confirm-btn > img {
        margin-right: 4px; }
    .table_row-buttons .reject-btn {
      width: 120px;
      height: 32px;
      border-radius: 4px;
      background-color: #fcecec;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #e44b4b;
      font-size: 10px;
      font-family: "MontBold";
      text-transform: uppercase;
      align-self: center; }
      .table_row-buttons .reject-btn > img {
        margin-right: 4px; } }

@media only screen and (max-width: 700px) {
  .content_block {
    padding: 48px 15px 89px; }
  .header_wrapper,
  footer {
    padding: 15px !important; }
    .header_wrapper .down-wrapper,
    footer .down-wrapper {
      display: flex;
      justify-content: center; }
  footer .upper-wrapper {
    flex-direction: column !important; } }

@media only screen and (max-width: 576px) {
  footer .upper-wrapper {
    flex-direction: column !important; }
    footer .upper-wrapper > div {
      margin-top: 15px !important; }
      footer .upper-wrapper > div a {
        font-size: 10px !important; }
  .table_row-buttons .confirm-btn.fullwidth {
    width: 100% !important; }
  .table_row-buttons .confirm-btn {
    width: 50% !important; }
  .table_row-buttons .reject-btn {
    width: 50% !important; }
  .activity_block {
    padding: 16px; }
  .activity_dialog {
    padding: 48px 24px; }
  .activity_dialog-title span {
    font-size: 24px; }
  .activity_dialog-txt {
    margin-top: 16px;
    white-space: break-spaces; }
  .modelBody.modal-body tbody tr:first-child {
    display: flex;
    flex-direction: column; }
    .modelBody.modal-body tbody tr:first-child img {
      margin-bottom: 20px; }
    .modelBody.modal-body tbody tr:first-child td {
      font-size: 12px; }
  .modal-content {
    border: none; }
  .modal-header,
  .modal-footer {
    width: 476px; }
  .table_row-elem.highlighted:before {
    top: -1px;
    width: 16px;
    left: -16px; }
  .table_row-elem.highlighted:after {
    top: -1px;
    width: 16px;
    right: -16px; }
  .activity_block .select_wrapper {
    margin-left: 00px; }
  .table_row-header,
  .table_row-status,
  .table_row-buttons {
    padding: 0 5px; } }
