.search-pagination-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    .pagination-list {
        display: flex;
        margin-bottom: 0;
        li {
            border-radius: 2px;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            -webkit-transition: all ease 0.3s;
            -moz-transition: all ease 0.3s;
            -ms-transition: all ease 0.3s;
            -o-transition: all ease 0.3s;
            transition: all ease 0.3s;
            &:hover {
                background: rgba(55, 150, 246, 0.2);
            }
            a {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                font-size: 20px;
            }
        }
    }

    .pagination-link {
        cursor: pointer;
        background: transparent;
        color: #3796f6;
        text-decoration: none;
        text-align: center;
        line-height: 28px;
        height: 24px;
        width: 24px;
        font-size: 14px;
        min-width: 24px;
        &.pagination-link-active {
            border-radius: 2px;
            background: #3796f6;
            color: #ffffff;
            &:hover {
                opacity: 0.7;
            }
        }
    }

    .pagination-ellipsis {
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        a {
            cursor: pointer;
            background: transparent;
            height: 24px;
            width: 24px;
            color: #3796f6;
            text-decoration: none;
            text-align: center;
            line-height: 28px;
        }
    }
    .next,
    .previous {
        margin: 0;

        &.disabled {
            &:hover {
                background: transparent;
            }
            a {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                font-size: 16px;
                fill: #3796f6;
            }
        }
    }
}
