.dashboard_page {
    display: flex;
    flex-flow: column;
    padding: 48px 64px 85px !important;
    .first-row {
        display: flex;
        justify-content: space-between;
        > div {
            margin-left: 33px;
            height: 288px;
            // width: 362px;
            width: 100%;
            padding: 32px 40px;
            border-radius: 10px;
            background-color: #ffffff;
            box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
        }
        > div:first-of-type {
            margin-left: 0;
        }
    }
    .second-row {
        padding: 32px 40px;
        margin-top: 32px;
        height: 330px;
        width: 100%;
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
        &-header {
            margin-bottom: 20px;
            > span {
                font-family: "MontSemiBold";
                font-size: 16px;
                cursor: pointer;
                color: #204569;
                opacity: 0.3;
            }
            > span:first-of-type {
                margin-right: 24px;
            }
            .active-header {
                opacity: 1;
            }
        }

        &-content {
            display: flex;
            justify-content: space-between;
            > div:first-of-type {
                width: 100%;
                margin-right: 64px;
            }
        }
    }
}
.category-header {
    margin-bottom: 20px;
    font-family: "MontSemiBold", sans-serif;
    color: #204569;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
}

.stock-level {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    > p {
        margin-bottom: 0px !important;
        font-family: "MontSemiBold", sans-serif;
        color: #204569;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
    }

    > div > span {
        font-family: "MontRegular";
        font-size: 14px;
        cursor: pointer;
        color: #8fa2b4;
    }
    .mm {
        margin-right: 24px;
    }
    .active {
        color: #204569;
    }
}

.chart-no-results {
    text-align: center;
    margin-top: 80px;
    font-size: 20px;
    font-family: "MontRegular";
}